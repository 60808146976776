<template>
    <v-ons-list-item tappable @click="dispatchClick">
        <div class="left">
            <img
                v-if="notification.isNew"
                class="list-item__thumbnail"
                style="width: auto !important; border-radius:0 !important;"
                src="images/notification.png"
            />
            <img
                v-else
                class="list-item__thumbnail"
                src="images/mail.png"
                style="height: auto !important; width: 30px !important; border-radius:0 !important;"
            />
        </div>
        <div class="center">
            <p
                style="font-weight: bold; margin:4px; display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;"
            >
                {{ notification.title }}
            </p>
            <p
                style="font-size: small; margin:4px; display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;"
            >
                {{ notification.description }}
            </p>
            <p style="margin:4px; font-size: smaller;font-style: italic;">
                <timeago :datetime="notification.created" :auto-update="30" />
            </p>
        </div>
        <div v-if="notification.isNew" class="right">
            <a
                href="#"
                class="notification-mark-read"
                title="Mark as read"
                @click.prevent="markAsRead"
            >
                <i
                    class="fa fa-times-circle"
                    aria-hidden="true"
                    style="color: darkred;"
                />
            </a>
        </div>
        <div v-if="!notification.isNew" class="right">
            <a
                href="#"
                class="notification-mark-read"
                title="Delete"
                @click.prevent="deleteNotification"
            >
                <i
                    class="fa fa-trash"
                    aria-hidden="true"
                    style="color: grey;"
                />
            </a>
        </div>
    </v-ons-list-item>
</template>

<script>
export default {
    name: "NotificationElement",
    props: {
        notification: {
            type: Object,
            required: true
        }
    },

    methods: {
        markAsRead(event) {
            this.$emit("read");
            event.stopImmediatePropagation();
        },
        deleteNotification(event) {
            this.$emit("delete");
            event.stopImmediatePropagation();
        },
        dispatchClick() {
            this.$emit("click");
        }
    }
};
</script>
