<template>
    <v-ons-page>
        <v-ons-toolbar class="header">
            <div class="center">
                <center><img src="/assets/images/mobile/logo.svg" /></center>
            </div>
        </v-ons-toolbar>

        <div class="background"></div>

        <div class="content">
            <v-ons-pull-hook
                :action="loadNotifications"
                @changestate="state = $event.state"
            >
                <span v-show="state === 'initial'">
                    {{ $t("pull-hookRefresh") }}
                </span>
                <span v-show="state === 'preaction'">
                    {{ $t("pull-hookRelease") }}
                </span>
                <span v-show="state === 'action'">
                    {{ $t("pull-hookLoading") }}
                </span>
            </v-ons-pull-hook>

            <v-ons-list v-if="hasNotifications">
                <v-ons-list-header
                    >{{ $t("title") }}
                    <span
                        style="font-size: xx-small;position: fixed;right: 0;margin-right: 10px;font-weight: bold;"
                        >{{ $t("pullhook") }}</span
                    ></v-ons-list-header
                >

                <v-ons-list-item expandable :expanded.sync="isExpanded_New">
                    {{ $t("New") }} ({{ new_total }})
                    <div class="expandable-content">
                        <v-ons-list>
                            <NotificationElement
                                v-for="notification in new_notifications"
                                :key="notification.id"
                                :notification="notification"
                                @read="markAsRead(notification)"
                                @click="showDetails(notification)"
                            />
                        </v-ons-list>
                    </div>
                </v-ons-list-item>
                <v-ons-list-item expandable :expanded.sync="isExpanded_Readed">
                    {{ $t("Readed") }} ({{ readed_total }})
                    <div class="expandable-content">
                        <v-ons-list>
                            <NotificationElement
                                v-for="notification in readed_notifications"
                                :key="notification.id"
                                :notification="notification"
                                @delete="markAsDelete(notification)"
                                @click="showDetails(notification)"
                            />
                        </v-ons-list>
                    </div>
                </v-ons-list-item>
            </v-ons-list>

            <center>
                <div
                    v-if="!hasNotifications"
                    class="center"
                    style="height: auto;border: 1px solid black;border-radius: 10px;padding: 10px;margin: 20px;"
                    v-html="$t('noNotifications')"
                ></div>
                <div v-if="!hasActiveSubscription" style="margin-top:10px;">
                    <v-ons-button @click="activateNotifications">{{
                        $t("signmein")
                    }}</v-ons-button>
                </div>
                <div v-if="hasActiveSubscription" style="margin-top:10px;">
                    <v-ons-button
                        modifier="quiet"
                        @click="deactivateNotifications"
                        >{{ $t("signmeout") }}</v-ons-button
                    >
                </div>
            </center>

            <center>
                <div style="margin-top:10px;">
                    <v-ons-button
                        modifier="cta"
                        style="background: crimson;"
                        @click="$router.push('share')"
                        >{{ $t("share_application") }}</v-ons-button
                    >
                </div>
            </center>

            <div style="text-align: center; width: 100%; margin-top:40px;">
                <p style="color:black;font-size:x-small;">
                    {{ $t("copyright") }}
                </p>
            </div>
        </div>

        <!-- DIALOGO QUE MUESTRA EL DETALLE DE LA NOTIFICACION -->
        <v-ons-dialog
            id="dialogNotification"
            cancelable
            :visible.sync="dialogVisible"
        >
            <div style="text-align:center;margin:10px;">
                <NotificationDetails
                    :notification="selectedNotification"
                ></NotificationDetails>
            </div>
            <div class="center" style="text-align:center;margin:10px;">
                <v-ons-button
                    modifier="cta"
                    style="font-size: x-small;width: 70px;min-height: 20px;line-height: 20px;"
                    @click="dialogVisible = false"
                >
                    {{ $t("btnClose") }}
                </v-ons-button>
            </div>
        </v-ons-dialog>
    </v-ons-page>
</template>

<script>
import NotificationElement from "./notifications/NotificationElement";
import NotificationDetails from "./notifications/NotificationDetails";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "Notifications",
    components: { NotificationElement, NotificationDetails },
    data() {
        return {
            new_total: 0,
            readed_total: 0,
            new_notifications: [],
            readed_notifications: [],
            dialogVisible: false,
            selectedNotification: null,
            state: "initial",
            isExpanded_New: false,
            isExpanded_Readed: false,
            activeSubscription: null,
            intervalId: 0
        };
    },
    computed: {
        hasNotifications() {
            return this.new_total + this.readed_total > 0;
        },
        hasUnread() {
            return this.new_total > 0;
        },
        hasActiveSubscription() {
            return this.activeSubscription != null;
        }
    },
    mounted() {
        this.initialiseSubcriptions();
        this.fetch();
        this.intervalId = setInterval(() => {
            this.fetch();
        }, 15000);
    },
    methods: {
        ...mapActions(["setUser"]),
        loadNotifications(done) {
            this.fetch(5, done);
        },
        fetch(limit = 5, done = null) {
            var that = this;
            if (localStorage.email) {
                axios
                    .get("/api/notifications", {
                        params: {
                            limit: limit,
                            patient_email: localStorage.email
                        }
                    })

                    .then(
                        ({
                            data: {
                                unread_notifications,
                                total_unread,
                                readed_notifications,
                                total_readed
                            }
                        }) => {
                            if (that.new_total != total_unread) {
                                that.new_total = total_unread;
                                that.new_notifications = unread_notifications.map(
                                    ({ id, data, created_at }) => {
                                        return {
                                            id: id,
                                            title: data.title,
                                            description: data.description,
                                            image: data.image,
                                            body: data.body,
                                            created: created_at,
                                            isNew: true
                                        };
                                    }
                                );
                                if (total_unread >= 1)
                                    that.isExpanded_New = true;
                            }

                            if (that.readed_total != total_readed) {
                                that.readed_total = total_readed;
                                that.readed_notifications = readed_notifications.map(
                                    ({ id, data, created_at }) => {
                                        return {
                                            id: id,
                                            title: data.title,
                                            description: data.description,
                                            image: data.image,
                                            body: data.body,
                                            created: created_at,
                                            isNew: false
                                        };
                                    }
                                );
                            }
                            if (done != null) done();
                        }
                    )

                    .catch(error => {
                        // Error 😨
                        if (error.response) {
                            /*
                             * The request was made and the server responded with a
                             * status code that falls out of the range of 2xx
                             */
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            /*
                             * The request was made but no response was received, `error.request`
                             * is an instance of XMLHttpRequest in the browser and an instance
                             * of http.ClientRequest in Node.js
                             */
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request and triggered an Error
                            console.log("Error", error.message);
                        }
                        console.log(error.config);
                        if (done != null) done();
                    });
            } else if (done != null) done();
        },
        markAsRead(notification) {
            const index = this.new_notifications.findIndex(
                n => n.id === notification.id
            );

            if (index > -1) {
                this.new_total--;
                this.new_notifications.splice(index, 1);
                axios.patch(`/api/notifications/${notification.id}/read`, {
                    patient_email: localStorage.email
                });
                this.readed_total++;
                notification.isNew = false;
                this.readed_notifications.unshift(notification);

                this.readed_notifications.sort(function(a, b) {
                    a = new Date(a.created);
                    b = new Date(b.created);
                    return a > b ? -1 : a < b ? 1 : 0;
                });
            }
        },
        markAsDelete(notification) {
            const index = this.readed_notifications.findIndex(
                n => n.id === notification.id
            );

            if (index > -1) {
                this.readed_total--;
                this.readed_notifications.splice(index, 1);
                axios.patch(`/api/notifications/${notification.id}/delete`, {
                    patient_email: localStorage.email
                });

                this.readed_notifications.sort(function(a, b) {
                    a = new Date(a.created);
                    b = new Date(b.created);
                    return a > b ? -1 : a < b ? 1 : 0;
                });
            }
        },
        showDetails(notification) {
            this.selectedNotification = notification;
            this.dialogVisible = true;
            if (notification.isNew) {
                this.markAsRead(notification);
            }
        },
        validEmail: function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        initialiseSubcriptions() {
            if (localStorage.iosNotificationsActivated) {
                this.activeSubscription = { endpoint: "ios" };
            }

            if (!("showNotification" in ServiceWorkerRegistration.prototype)) {
                console.log("Notifications aren't supported.");
                return;
            }
            if (Notification.permission === "denied") {
                console.log("The user has blocked notifications.");
                return;
            }

            if (this.isIos()) {
                console.log("Push messaging isn't supported in IOS.");
                return;
            }

            if (!("PushManager" in window)) {
                console.log("Push messaging isn't supported.");
                return;
            }

            navigator.serviceWorker.ready
                .then(registration => {
                    registration.pushManager
                        .getSubscription()
                        .then(subscription => {
                            if (!subscription) {
                                this.activeSubscription = null;
                                console.log("No hay una subscripcion activa.");
                            } else {
                                this.activeSubscription = subscription;
                                console.log(
                                    "Se recupera subscripcion existente"
                                );
                            }
                        })
                        .catch(ex => {
                            console.error("Error during getSubscription()", ex);
                        });
                })
                .catch(ex => {
                    console.error("Error during registration()", ex);
                });

            navigator.serviceWorker.addEventListener("message", event => {
                if (event.data.msg == "new_notification") {
                    this.fetch();
                }
            });
        },
        activateNotifications() {
            if (!localStorage.email) {
                this.$ons.notification
                    .prompt({
                        inputType: "text",
                        title: this.$t("promptTitle"),
                        message: this.$t("promptMessage")
                    })
                    .then(response => {
                        if (this.validEmail(response)) {
                            this.setUser({
                                email: response,
                                default_locale: localStorage.language
                            });
                            localStorage.email = response;
                            if (this.isIos()) {
                                localStorage.iosNotificationsActivated = true;
                                this.activeSubscription = { endpoint: "ios" };
                                this.updateSubscriptionDB(
                                    this.activeSubscription
                                );
                            } else this.registerNewSubscription();
                        } else {
                            this.$ons.notification.toast({
                                message: this.$t("error_emailNotValid"),
                                buttonLabel: this.$t("btnClose"),
                                timeout: 4000,
                                force: true
                            });
                        }
                    });
            } else {
                if (this.isIos()) {
                    localStorage.iosNotificationsActivated = true;
                    this.activeSubscription = { endpoint: "ios" };
                    this.updateSubscriptionDB(this.activeSubscription);
                } else this.registerNewSubscription();
            }
        },
        registerNewSubscription() {
            localStorage.disableNotification = false;
            navigator.serviceWorker.ready.then(registration => {
                const options = { userVisibleOnly: true };
                const vapidPublicKey = process.env.MIX_VAPID_PUBLIC_KEY;
                if (vapidPublicKey) {
                    console.log(
                        "Se procesa registro con clave publica: " +
                            vapidPublicKey
                    );
                    options.applicationServerKey = this.urlBase64ToUint8Array(
                        vapidPublicKey
                    );
                    registration.pushManager
                        .subscribe(options)
                        .then(subscription => {
                            this.activeSubscription = subscription;
                            console.log("Se actualiza la BD");
                            this.updateSubscriptionDB(subscription);
                        })
                        .catch(ex => {
                            console.error("Error during subscribe()", ex);
                        });
                }
            });
        },
        updateSubscriptionDB(subscription) {
            var data;

            if (subscription.endpoint != "ios") {
                const key = subscription.getKey("p256dh");
                const token = subscription.getKey("auth");
                const contentEncoding = (PushManager.supportedContentEncodings || [
                    "aesgcm"
                ])[0];

                data = {
                    patient_email: localStorage.email,
                    endpoint: subscription.endpoint,
                    key: key
                        ? btoa(
                              String.fromCharCode.apply(
                                  null,
                                  new Uint8Array(key)
                              )
                          )
                        : null,
                    token: token
                        ? btoa(
                              String.fromCharCode.apply(
                                  null,
                                  new Uint8Array(token)
                              )
                          )
                        : null,
                    contentEncoding: contentEncoding
                };
            } else {
                data = {
                    patient_email: localStorage.email,
                    endpoint: subscription.endpoint
                };
            }

            axios
                .post("/api/subscriptions", data)
                .then(() => {
                    console.log(
                        "Subcripcion a notificaciones actualizada correctamente en BD"
                    );
                    this.fetch();
                })
                .catch(ex => {
                    console.error(
                        "Error al intentar registrar la informacion de la subscripcion en el servidor"
                    );
                });
        },
        deactivateNotifications() {
            this.$ons.notification
                .confirm(this.$t("confirm_unsubscribe"), {
                    buttonLabels: [
                        this.$t("confirm_no"),
                        this.$t("confirm_yes")
                    ],
                    title: " "
                })
                .then(response => {
                    if (response) {
                        this.unsubscribe(this.activeSubscription);
                        localStorage.disableNotification = true;
                    }
                });
        },
        unsubscribe(subscription) {
            if (this.isIos) {
                this.deleteSubscriptionDB(subscription);
            } else {
                subscription
                    .unsubscribe()
                    .then(() => {
                        this.deleteSubscriptionDB(subscription);
                    })
                    .catch(ex => {
                        Console.error(
                            "Error al intentar desregistrar la informacion de la subscripcion en el GOOGLE:" +
                                ex
                        );
                    });
            }
        },
        deleteSubscriptionDB(subscription) {
            axios
                .post("/api/subscriptions/delete", {
                    patient_email: localStorage.email,
                    endpoint: subscription.endpoint
                })
                .then(() => {
                    this.$ons.notification.toast({
                        message: this.$t("notifications_deactivated"),
                        buttonLabel: this.$t("btnClose"),
                        timeout: 4000,
                        force: true
                    });
                    this.activeSubscription = null;
                    console.log("Subscripcion cancelada correctamente");
                })
                .catch(ex => {
                    console.error(
                        "Error al intentar desregistrar la informacion de la subscripcion en el servidor: " +
                            ex
                    );
                });
        },
        urlBase64ToUint8Array(base64String) {
            const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
            const base64 = (base64String + padding)
                .replace(/-/g, "+")
                .replace(/_/g, "/");
            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);
            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        },
        isIos() {
            var userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        }
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    }
};
</script>

<style scope></style>

<i18n>
{
  "en": {
    "title": "NOTIFICATIONS",
    "markAllRead": "Mark all as read",
    "noNotifications": "You have not received any<br>News or Notifications yet.",
    "pull-hookRefresh": "Pull to refresh",
    "pull-hookRelease": "Release",
    "pull-hookLoading": "Loading...",
    "New" : "New",
    "Readed": "Read",
    "pullhook": "Pull to Reload",
    "signmein": "Activate News & Notifications",
    "signmeout": "Turn off News & Notifications",
    "promptTitle": "Activate Notifications",
    "promptMessage": "Please, enter your e-Mail address",
    "error_emailNotValid": "E-Mail field must be in a valid format.",
    "notifications_deactivated": "Notifications & News subscription has been deactivated.",
    "share_application": "Share PACCO with your friends",
    "confirm_unsubscribe": "Do you want to unsubscribe from Notifications and News?",
    "confirm_yes": "Yes",
    "confirm_no": "No",
    "btnClose": "CLOSE",
    "copyright": "COPYRIGHT @ 2020 SURFERNET. ALL RIGHTS RESERVED."
  },
  "es": {
    "title": "NOTIFICACIONES",
    "markAllRead": "Marcar notificaciones como leidas",
    "noNotifications": "No has recibido todavía ninguna<br>Noticia o Notificación.",
    "pull-hookRefresh": "Desliza para Recargar",
    "pull-hookRelease": "Suelta",
    "pull-hookLoading": "Cargando...",
    "New" : "Nuevas",
    "Readed": "Leidas",    
    "pullhook": "Desliza para Recargar",
    "signmein": "Activar Notificaciones",
    "signmeout": "Desactivar Nuevas Notificaciones",
    "promptTitle": "Activar Notificaciones",
    "promptMessage": "Por favor, proporciona tu dirección de e-Mail",
    "error_emailNotValid": "El campo E-Mail debe ser contener una dirección de válida.",
    "notifications_deactivated": "La subscripción a Notificaciones y Noticias ha sido desactivada.",
    "share_application": "Comparte PACCO con tus amigos",
    "confirm_unsubscribe": "¿Desea cancelar su subcripción a Notificaciones y Noticias?",    
    "confirm_yes": "Si",
    "confirm_no": "No",
    "btnClose": "CERRAR",
    "copyright": "COPYRIGHT @ 2020 SURFERNET. ALL RIGHTS RESERVED." 
  }
}
</i18n>
