<template>
    <v-ons-page>
        <v-ons-toolbar class="header">
            <div class="center">
                <center><img src="/assets/images/mobile/logo.svg" /></center>
            </div>
        </v-ons-toolbar>

        <div class="background"></div>

        <div class="content" style="margin:15px;">
            <center>
                <p
                    style="font-size: large;font-weight: bold"
                    v-html="$t('title')"
                ></p>
                <img
                    class="logo_surfernet"
                    src="/assets/images/mobile/logosurfernet.png"
                />
                <p style="font-size: small;" v-html="$t('subtitle01')"></p>
                <p style="font-size: small;" v-html="$t('doctor')"></p>
                <p style="font-size: small;" v-html="$t('subtitle03')"></p>
                <img
                    class="logo_grap"
                    src="/assets/images/mobile/logograp.png"
                />
            </center>

            <div style="text-align: center; width: 100%;">
                <router-link
                    style="margin-top:30px; padding: 10px 100px; background-color: #a4ffff !important; color:black;"
                    to="/"
                    tag="v-ons-button"
                    >{{ $t("btnClose") }}</router-link
                >
                <p style="color:black;font-size:x-small;">
                    {{ $t("copyright") }}
                </p>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
export default {
    name: "Credits"
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
    .logo_surfernet {
        width: 80%;
        margin-top: 10px;
    }

    .logo_grap {
        width: 27%;
    }
}

@media only screen and (min-width: 600px) {
    .logo_surfernet {
        width: 30%;
        margin-top: 10px;
    }

    .logo_grap {
        width: 12%;
    }
}
</style>

<i18n>
{
  "en": {
    "title": "PACCO Version 2.0",
    "subtitle01": "Medical algorithms provided by:",
    "doctor": "Dr. Jesus Molina Paris<br>SOMAMFYC Respiratory Group", 
    "subtitle03": "<b><i>Members of<br>Sociedad de Respiratorio en Atención Primaria (GRAP)</i></b>",    
    "btnClose": "CLOSE",
    "copyright": "COPYRIGHT @ 2020 SURFERNET. ALL RIGHTS RESERVED."
  },
  "es": {
    "title": "PACCO Version 2.0",
    "subtitle01": "Algoritmos médicos desarrollados por:",
    "doctor": "Dr. Jesús Molina París<br>Grupo de Respiratorio de SOMAMFYC", 
    "subtitle03": "<b><i>Miembros de<br>Sociedad de Respiratorio en Atención Primaria (GRAP)</i></b>",    
    "btnClose": "CERRAR",
    "copyright": "COPYRIGHT @ 2020 SURFERNET. ALL RIGHTS RESERVED."
  }
}
</i18n>
