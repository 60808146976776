import VueRouter from 'vue-router';

let routes = [{
        path: '/',
        name: "Home",
        component: require('./components/Home').default
    },
    {
        path: '/autocheck',
        name: 'AutoCheck',
        component: require('./components/AutoCheck').default
    },
    {
        path: '/privacy',
        name: 'PrivacyPolicy',
        component: require('./components/PrivacyPolicy').default
    },
    {
        path: '/terms',
        name: 'TermsAndConditions',
        component: require('./components/TermsAndConditions').default
    },
    {
        path: '/recommendations',
        name: 'Recommendations',
        component: require('./components/Recommendations').default
    }, 
    {
        path: '/credits',
        name: 'Credits',
        component: require('./components/Credits').default
    },
    {
        path: '/share',
        name: 'Share',
        component: require('./components/Share').default
    }, 
    {
        path: '/notifications',
        name: 'Notifications',
        component: require('./components/Notifications').default
    }
];


export default new VueRouter({
    routes,
});