<template>
    <v-ons-page>
        <div class="background"></div>

        <div class="content">
            <div class="center">
                <center>
                    <img class="logo" src="/assets/images/mobile/logo.svg" />
                </center>
            </div>

            <div class="center">
                <center v-if="this.diagnostic.rating.value == 0">
                    <img
                        class="main_logo"
                        src="/assets/images/mobile/results01.svg"
                    />
                </center>
                <center
                    v-if="
                        this.diagnostic.rating.value > 1 &&
                            this.diagnostic.rating.value <= 50
                    "
                >
                    <img
                        class="main_logo"
                        src="/assets/images/mobile/results02.svg"
                    />
                </center>
                <center
                    v-if="
                        this.diagnostic.rating.value > 51 &&
                            this.diagnostic.rating.value <= 70
                    "
                >
                    <img
                        class="main_logo"
                        src="/assets/images/mobile/results02.svg"
                    />
                </center>
                <center v-if="this.diagnostic.rating.value > 70">
                    <img
                        class="main_logo"
                        src="/assets/images/mobile/results03.svg"
                    />
                </center>
            </div>

            <div
                class="center"
                style="margin:0px 40px; color:white; font-size:large; font-weight:bold;"
            >
                <center>
                    <p
                        v-if="this.diagnostic.rating.value == 0"
                        v-html="$t('title01')"
                    />
                    <p
                        v-if="
                            this.diagnostic.rating.value > 1 &&
                                this.diagnostic.rating.value <= 50
                        "
                        v-html="$t('title02')"
                    />
                    <p
                        v-if="
                            this.diagnostic.rating.value > 51 &&
                                this.diagnostic.rating.value <= 70
                        "
                        v-html="$t('title03')"
                    />
                    <p
                        v-if="this.diagnostic.rating.value > 70"
                        v-html="$t('title04')"
                    />
                </center>
            </div>

            <div
                style="max-width:500px;margin: 10px auto 10px  auto; padding: 0 40px ; color:white; font-size:normal;"
            >
                <p
                    v-if="this.diagnostic.rating.value == 0"
                    v-html="$t('subtitle01_01')"
                />
                <p
                    v-if="
                        this.diagnostic.rating.value > 1 &&
                            this.diagnostic.rating.value <= 50
                    "
                    v-html="$t('subtitle01_02')"
                />
                <p
                    v-if="
                        this.diagnostic.rating.value > 51 &&
                            this.diagnostic.rating.value <= 70
                    "
                    v-html="$t('subtitle01_03')"
                />
                <p
                    v-if="this.diagnostic.rating.value > 70"
                    v-html="$t('subtitle01_04')"
                />
            </div>

            <div
                v-if="this.diagnostic.rating.value < 70"
                style="max-width:500px;margin: 10px auto 10px  auto; padding: 0 40px; color:white; font-size:normal;"
            >
                <p v-html="$t('subtitle02')" />
            </div>

            <center>
                <router-link to="/recommendations">
                    <v-ons-button
                        style="margin-top:10px; margin-bottom:10px; padding: 10px 30px; background-color: #29ffa6 !important; color:gray;"
                    >
                        {{ $t("btnRecommendations") }}
                    </v-ons-button>
                </router-link>
            </center>

            <div
                style="max-width:500px;margin: 10px auto 10px  auto; padding: 0 40px ;"
            >
                <div
                    style="color:white; font-size:small; border-radius: 2px 2px 2px 2px; -moz-border-radius: 2px 2px 2px 2px; -webkit-border-radius: 2px 2px 2px 2px; border: 1px solid #ffffff; padding: 5px;text-align: justify;text-justify: inter-word"
                >
                    <p v-html="$t('subtitle03')" />
                </div>
            </div>

            <center>
                <v-ons-button
                    style="margin-top:10px; margin-bottom:10px; padding: 10px 30px; background-color: #a4ffff !important; color:black;"
                    @click="Continue_Click"
                >
                    {{ $t("btnContinue") }}
                </v-ons-button>
            </center>
        </div>
    </v-ons-page>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "Results",
    data() {
        return {};
    },
    computed: mapGetters(["user", "diagnostic"]),
    mounted() {},
    methods: {
        Continue_Click: function(event) {
            this.$emit("finish-AutoCheck");
        }
    }
};
</script>

<style scoped>
.background {
    background-image: url(/assets/images/mobile/background.svg) !important;
    background-repeat: no-repeat !important;
    background-position-y: center !important;
    background-position-x: center !important;
    background-size: cover !important;
    background-color: rgba(46, 120, 142, 1) !important;
}

.page__content {
    font-family: raleway;
}

@media only screen and (max-width: 600px) {
    .logo {
        width: 70%;
        margin-top: 20px;
    }
    .main_logo {
        width: 60%;
        margin-top: 40px;
    }
}

@media only screen and (min-width: 600px) {
    .logo {
        width: 70%;
        margin-top: 5px;
    }
    .main_logo {
        width: 40%;
        margin-top: 40px;
    }
}
</style>

<i18n>
{
  "en": {
    "title01": "NO COVID-19 SYMPTOMS",
    "title02": "POSSIBLE MILD INFECTION",
    "title03": "POSSIBLE MODERATE INFECTION",
    "title04": "POSSIBLE SERIOUS INFECTION.<br>GO TO YOUR HOSPITAL OR MEDICAL CENTER",
    "subtitle01_01": " ",
    "subtitle01_02": "If you think your symptoms could be related with the COVID-19 epidemic, we recommend you to run this autocheck screening again in 24 hours and, if the results get worse, make periodic telephone consultations with your doctor or healthcare professional.",
    "subtitle01_03": "We believe that you need to have a DAILY follow-up from your Primary Care physician. Please contact your doctor or healthcare professional.",
    "subtitle01_04": "We strongly recommend that you contact your nearest Health Center or Hospital as SOON AS POSSIBLE.",
    "subtitle02": "Autocheck your symptoms again after 24 hours, and if in any case your condition gets worse, please contact your nearest Medical Center or Hospital.",
    "subtitle03": "The results of this program are shown only as recommendations for the user to provide support in identifying a possible infection by Coronavirus (COVID-19). Under no circumstances these recommendations constitute a medical diagnosis. Check with your doctor or health care professional.",
    "btnContinue": "FINISH",
    "btnRecommendations": "REVIEW RECOMMENDATIONS"
  },
  "es": {
    "title01": "SIN SÍNTOMAS DE COVID-19",
    "title02": "POSIBLE INFECCIÓN LEVE",
    "title03": "POSIBLE INFECCIÓN MODERADA",
    "title04": "POSIBLE INFECCÍON GRAVE.<br>ACUDA A SU HOSPITAL O CENTRO MÉDICO",
    "subtitle01_01": " ",
    "subtitle01_02": "Si piensa que sus síntomas pudieran estar relacionados con el COVID-19, le recomendamos que vuelva a realizar esta automedición en 24 horas y si los resultados empeoran realice consultas telefónicas periódicas con su médico o profesional sanitario.",
    "subtitle01_03": "Creemos que precisa llevar un seguimiento DIARIO desde Atención Primaria. Por favor, contacte con su médico o profesional sanitario.",
    "subtitle01_04": "Te recomedamos encarecidamente que contactes con la MAYOR BREVEDAD POSIBLE con tu centro de salud u hospital más cercano.",
    "subtitle02": "Vuelve a autoevaluarte dentro en las próximas 24 horas, y si en cualquier caso tu estado empeora, contacta con tu centro médico u hospital más cercano.",
    "subtitle03": "Los resultados de este programa son únicamente recomendaciones para el usuario, como ayuda y apoyo a la identificación de un posible contagio por Coronavirus (COVID-19) y no constituyen un diagnóstico médico. Consulte con su médico o profesional sanitario.",
    "btnContinue": "REPETIR AUTOMEDICIÓN",
    "btnRecommendations": "VER RECOMENDACIONES"
  }
}
</i18n>
