<template>
  <component v-bind:is="currentPage" v-on:change-Page="NavigateScreen" v-on:finish-AutoCheck="FinishAutoCheck"></component>
</template>

<script>
import personaldata from './autocheck/PersonalData.vue';
import autodiagnostic from './autocheck/AutoDiagnostic.vue';
import results from './autocheck/Results.vue';

export default {
  name: 'AutoCheck',
  methods: {
    NavigateScreen (newScreen)
    {      
      this.currentPage = newScreen;
    },
    FinishAutoCheck ()
    {
      this.$router.replace({ name: 'Home'});
    }
  },
  data() {
    return {
      currentPage: 'personaldata',
      pages: ['personaldata', 'autodiagnostic', 'results'],      
    };
  },
  components: {
    personaldata,
    autodiagnostic,
    results,
  }  
}
</script>