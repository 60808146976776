<template>
    <v-ons-splitter>
        <!-- MENU LATERAL  -->
        <v-ons-splitter-side
            swipeable
            width="220px"
            collapse=""
            side="left"
            :open.sync="openSide"
        >
            <v-ons-page>
                <v-ons-list>
                    <v-ons-list-item
                        tappable
                        @click="
                            ClickOnMenu('Home');
                            openSide = false;
                        "
                    >
                        <div class="center">
                            <v-ons-icon
                                icon="fa-home"
                                style="color: cadetblue;"
                            ></v-ons-icon
                            ><span style="margin-left:10px;">{{
                                $t("menu_home")
                            }}</span>
                        </div>
                    </v-ons-list-item>

                    <v-ons-list-item expandable :expanded.sync="isExpanded">
                        <div>
                            <v-ons-icon
                                icon="fa-flag"
                                style="color: brown;"
                            ></v-ons-icon
                            ><span style="margin-left:10px;">{{
                                $t("menu_language")
                            }}</span>
                        </div>
                        <div class="expandable-content">
                            <div
                                style="display:flex;"
                                @click="ChangeLocale('en')"
                            >
                                <country-flag
                                    country="us"
                                    style="margin-top:-12px;"
                                />
                                <div
                                    style="margin-top: 12px; margin-left: 12px;"
                                >
                                    {{ $t("lang_English") }}
                                </div>
                            </div>
                            <div
                                style="display:flex;"
                                @click="ChangeLocale('es')"
                            >
                                <country-flag
                                    country="es"
                                    style="margin-top:-12px;"
                                />
                                <div
                                    style="margin-top: 12px; margin-left: 12px;"
                                >
                                    {{ $t("lang_Spanish") }}
                                </div>
                            </div>
                        </div>
                    </v-ons-list-item>

                    <v-ons-list-item
                        tappable
                        @click="
                            ClickOnMenu('Recommendations');
                            openSide = false;
                        "
                    >
                        <div class="center">
                            <v-ons-icon
                                icon="fa-thumbs-up"
                                style="color: darkorange;"
                            ></v-ons-icon
                            ><span style="margin-left:10px;">{{
                                $t("menu_recommendations")
                            }}</span>
                        </div>
                    </v-ons-list-item>
                    <v-ons-list-item
                        tappable
                        @click="
                            ClickOnMenu('Notifications');
                            openSide = false;
                        "
                    >
                        <div class="center">
                            <v-ons-icon
                                icon="fa-bell"
                                style="color: red;"
                            ></v-ons-icon
                            ><span style="margin-left:10px;">{{
                                $t("menu_notifications")
                            }}</span>
                        </div>
                    </v-ons-list-item>
                    <v-ons-list-item
                        tappable
                        @click="
                            ClickOnMenu('Share');
                            openSide = false;
                        "
                    >
                        <div class="center">
                            <v-ons-icon
                                icon="fa-share-alt"
                                style="color: blue;"
                            ></v-ons-icon
                            ><span style="margin-left:10px;">{{
                                $t("menu_share")
                            }}</span>
                        </div>
                    </v-ons-list-item>
                    <v-ons-list-item
                        tappable
                        @click="
                            ClickOnMenu('Credits');
                            openSide = false;
                        "
                    >
                        <div class="center">
                            <v-ons-icon
                                icon="fa-medal"
                                style="color: gold;"
                            ></v-ons-icon
                            ><span style="margin-left:10px;">{{
                                $t("menu_credits")
                            }}</span>
                        </div>
                    </v-ons-list-item>
                    <v-ons-list-item
                        tappable
                        @click="
                            ClickOnMenu('TermsAndConditions');
                            openSide = false;
                        "
                    >
                        <div class="center">
                            <v-ons-icon
                                icon="fa-file-invoice"
                                style="color: green;"
                            ></v-ons-icon
                            ><span style="margin-left:10px;">{{
                                $t("menu_terms")
                            }}</span>
                        </div>
                    </v-ons-list-item>
                    <v-ons-list-item
                        tappable
                        @click="
                            ClickOnMenu('PrivacyPolicy');
                            openSide = false;
                        "
                    >
                        <div class="center">
                            <v-ons-icon
                                icon="fa-unlock-alt"
                                style="color: grey;"
                            ></v-ons-icon
                            ><span style="margin-left:10px;">{{
                                $t("menu_privacypolicy")
                            }}</span>
                        </div>
                    </v-ons-list-item>
                </v-ons-list>
            </v-ons-page>
        </v-ons-splitter-side>

        <!-- PAGINA PRINCIPAL -->
        <v-ons-splitter-content>
            <!-- BOTON PARA MOSTRAR EL MENU SUPERIOR -->
            <v-ons-button
                @click="openSide = true"
                style="z-index: 1000; position: absolute; top: 0; left: 10px; background-color: transparent;"
            >
                <v-ons-icon icon="fa-bars"></v-ons-icon>
            </v-ons-button>
            <router-view />
        </v-ons-splitter-content>

        <!-- POPUP INFERIOR PARA PEDIR LA INSTALACION EN ANDROID -->
        <v-ons-toast :visible.sync="ios_a2hs" animation="ascend">
            <p>
                <b>{{ $t("install_ios01") }}</b>
            </p>
            <p>
                {{ $t("install_ios02")
                }}<v-ons-icon icon="md-open-in-browser"></v-ons-icon
                >{{ $t("install_ios03") }}
            </p>
            <button @click="ios_a2hs = false">{{ $t("btnClose") }}</button>
        </v-ons-toast>
        <!-- POPUP INFERIOR PARA PEDIR LA ACTUALIZACION -->
        <v-ons-toast
            class="background_update"
            :visible.sync="update_available"
            animation="ascend"
            style="background-color:white;"
        >
            <p>
                {{ $t("update01")
                }}<v-ons-button @click="reload_update">{{
                    $t("btnHere")
                }}</v-ons-button
                >{{ $t("update02") }}
            </p>
        </v-ons-toast>
    </v-ons-splitter>
</template>

<script>
import CountryFlag from "vue-country-flag";

export default {
    name: "App",
    components: {
        CountryFlag
    },
    data() {
        return {
            ios_a2hs: false,
            update_available: false,
            newWorker: null,
            openSide: false,
            isExpanded: false,
            email: null
        };
    },
    created() {
        if (localStorage.language)
            this.$timeago.locale = this.$root._i18n.locale =
                localStorage.language;
        else
            this.ChangeLocale(
                navigator.language.substr(0, 2) ||
                    navigator.userLanguage.substr(0, 2)
            );
    },
    mounted() {
        if (this.isIos() && !this.isInStandaloneMode()) {
            this.ios_a2hs = true;
        }
        this.registerServiceWorker();
    },
    methods: {
        ChangeLocale(newLocale) {
            this.$timeago.locale = localStorage.language = this.$root._i18n.locale = newLocale;
        },
        ClickOnMenu(newScreen) {
            if (this.$router.currentRoute.name != newScreen) {
                this.$router.replace({ name: newScreen }).catch(err => {
                    console.error("Error routing the view: " + err);
                });
            }
        },
        isIos() {
            var userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        },
        isInStandaloneMode() {
            return (
                "standalone" in window.navigator && window.navigator.standalone
            );
        },
        reload_update() {
            this.newWorker.postMessage({ action: "skipWaiting" });
        },
        registerServiceWorker() {
            var refreshing = false;
            if ("serviceWorker" in navigator) {
                navigator.serviceWorker
                    .register("./service-worker.js")
                    .then(reg => {
                        reg.addEventListener("updatefound", () => {
                            // An updated service worker has appeared in reg.installing!
                            this.newWorker = reg.installing;
                            this.newWorker.addEventListener(
                                "statechange",
                                () => {
                                    // Has service worker state changed?
                                    switch (this.newWorker.state) {
                                        case "installed":
                                            // There is a new service worker available, show the notification
                                            if (
                                                navigator.serviceWorker
                                                    .controller
                                            )
                                                this.update_available = true;
                                            break;
                                    }
                                }
                            );
                        });
                    })
                    .catch(function(err) {
                        console.error(
                            "ServiceWorker registration failed: ",
                            err
                        );
                    });

                navigator.serviceWorker.addEventListener(
                    "controllerchange",
                    function() {
                        if (refreshing) return;
                        window.location.reload();
                        refreshing = true;
                    }
                );
            } else
                console.error(
                    "Service workers aren't supported in this browser."
                );
        }
    }
};
</script>

<style>
.dialog {
    width: 90%;
}

.dialog img {
    width: auto;
}

.header img {
    width: 180px;
    margin-top: 3px;
}

.header {
    background-image: url(/assets/images/mobile/background.svg) !important;
    background-repeat: no-repeat !important;
    background-position-y: center !important;
    background-position-x: center !important;
    background-size: cover !important;
    background-color: rgba(46, 120, 142, 1) !important;
}

.background_update {
    background-image: url(/assets/images/mobile/background_update.svg) !important;
    background-repeat: no-repeat !important;
    background-position-y: center !important;
    background-position-x: center !important;
    background-size: cover !important;
    background-color: rgba(46, 120, 142, 1) !important;
}
</style>

<i18n>
{
  "en": {
    "install_ios01": "Install the App for quick and easy access on the go",
    "install_ios02": "Tap ",
    "install_ios03": " in the bar below and then 'Add to Home Screen'",
    "btnClose": "Close",
    "update01": "A new version of this app is available. Click ",
    "btnHere": "Here",
    "update02": " to update.",
    "menu_home": "Home",
    "menu_language": "Language",
    "menu_recommendations": "Recommendations",
    "menu_notifications": "Notifications",
    "menu_terms": "Terms and Conditions",
    "menu_privacypolicy": "Privacy Policy",
    "menu_share": "Share",
    "menu_credits": "Credits", 
    "lang_Spanish": "Spanish",
    "lang_English": "English"
  },
  "es": {
    "install_ios01": "Instala la aplicación en tu Pantalla de inicio para tener un fácil acceso.",
    "install_ios02": "Haz clic ",
    "install_ios03": " en la barra inferior y luego presiona la opción de 'Añadir a la página de inicio'",
    "btnClose": "Cerrar",
    "update01": "Hay una nueva versión disponible de la aplicación. Haz clic ",
    "btnHere": "Aquí",
    "update02": " para actualizarla.",
    "menu_home": "Inicio",
    "menu_language": "Idioma",
    "menu_recommendations": "Recomendaciones",
    "menu_notifications": "Notificaciones",
    "menu_terms": "Términos y condiciones",
    "menu_privacypolicy": "Política de Privacidad",
    "menu_share": "Compartir",
    "menu_credits": "Créditos", 
    "lang_Spanish": "Español",
    "lang_English": "Inglés"
  }
}
</i18n>
