<template>
    <div v-if="notification != null" style="height: 480px;overflow: auto;">
        <p style="text-align: center; font-size:large;font-weight: bold;">
            {{ notification.title }}
        </p>
        <p
            style="text-align: left; background-color: lightgrey; padding: 10px;"
        >
            {{ notification.description }}
        </p>
        <img style="max-width: 100%;" :src="notification.image" />
        <div v-html="notification.body" style="text-align: left;"></div>
        <p style="font-size: small; font-style: italic; text-align: right;">
            <timeago :datetime="notification.created" :auto-update="30" />
        </p>
    </div>
</template>

<script>
export default {
    name: "NotificationDetails",
    props: {
        notification: {
            type: Object,
            default: null
        }
    }
};
</script>
