<template>
    <v-ons-page>
        <v-ons-toolbar class="header">
            <div class="center">
                <center><img src="/assets/images/mobile/logo.svg" /></center>
            </div>
        </v-ons-toolbar>

        <div class="background"></div>

        <div
            class="content, text_blue"
            style="margin:15px; font-family: raleway !important;"
        >
            <h1 class="text_blue" v-html="$t('title')"></h1>
            <p v-html="$t('subtitle')"></p>
            <!-- RECOMMENDATION: WASH YOUR HANDS -->
            <hr />
            <ons-row>
                <ons-col width="80px">
                    <img
                        src="/assets/images/mobile/recommendation01.svg"
                        style="width:50px; margin:10px;"
                    />
                </ons-col>
                <ons-col>
                    <p v-html="$t('reco01')"></p>
                    <p v-html="$t('reco01_why')"></p>
                </ons-col>
            </ons-row>
            <!-- RECOMMENDATION: MAINTAIN SOCIAL DISTANCING -->
            <hr />
            <ons-row>
                <ons-col width="80px">
                    <img
                        src="/assets/images/mobile/recommendation04.svg"
                        style="width:50px; margin:10px;"
                    />
                </ons-col>
                <ons-col>
                    <p v-html="$t('reco04')"></p>
                    <p v-html="$t('reco04_why')"></p>
                </ons-col>
            </ons-row>
            <!-- RECOMMENDATION: AVOID TOUCHING EYES -->
            <hr />
            <ons-row>
                <ons-col width="80px">
                    <img
                        src="/assets/images/mobile/recommendation03.svg"
                        style="width:50px; margin:10px;"
                    />
                </ons-col>
                <ons-col>
                    <p v-html="$t('reco03')"></p>
                    <p v-html="$t('reco03_why')"></p>
                </ons-col>
            </ons-row>
            <!-- RECOMMENDATION: PRACTICE RESPIRATORY HYGIENE -->
            <hr />
            <ons-row>
                <ons-col width="80px">
                    <img
                        src="/assets/images/mobile/recommendation02.svg"
                        style="width:50px; margin:10px;"
                    />
                </ons-col>
                <ons-col>
                    <p v-html="$t('reco02')"></p>
                    <p v-html="$t('reco02_why')"></p>
                </ons-col>
            </ons-row>
            <!-- RECOMMENDATION: FEVER -->
            <hr />
            <ons-row>
                <ons-col width="80px">
                    <img
                        src="/assets/images/mobile/recommendation05.svg"
                        style="width:50px; margin:10px;"
                    />
                </ons-col>
                <ons-col>
                    <p v-html="$t('reco05')"></p>
                    <p v-html="$t('reco05_why')"></p>
                </ons-col>
            </ons-row>

            <div style="text-align: center; width: 100%;">
                <v-ons-button
                    style="margin-top:10px; margin-bottom:30px; padding: 10px 80px; background-color: #a4ffff !important; color:black;"
                    @click="$router.replace({ name: 'Home' })"
                >
                    {{ $t("btnClose") }}
                </v-ons-button>

                <p style="color:black;font-size:x-small;">
                    {{ $t("copyright") }}
                </p>
            </div>
        </div>
    </v-ons-page>
</template>

<script>
export default {
    name: "Recommendations",
    data() {
        return {};
    },
    methods: {}
};
</script>

<style scoped>
.text_blue {
    color: #020646;
    font-family: raleway !important;
}

.text_red {
    color: #a92526 !important;
    font-family: raleway !important;
}
</style>

<i18n>
{
  "en": {
    "title": "Recommendations",
    "subtitle": "Basic protective measures against the new Coronavirus (COVID-19) recommended by World Health Organization.",
    "reco01": "<b style='color:#a72225'>Wash your hands frequently</b><br>Regularly and thoroughly clean your hands with an alcohol-based hand rub or wash them with soap and water.",
    "reco01_why": "<b style='color:#a72225'>Why?</b><br>Washing your hands with soap and water or using alcohol-based hand rub kills viruses that may be on your hands.",
    "reco02": "<b style='color:#a72225'>Practice respiratory hygiene</b><br>Make sure you, and the people around you, follow good respiratory hygiene. This means covering your mouth and nose with your bent elbow or tissue when you cough or sneeze. Then dispose of the used tissue immediately.",
    "reco02_why": "<b style='color:#a72225'>Why?</b><br>Droplets spread virus. By following good respiratory hygiene you protect the people around you from viruses such as cold, flu and COVID-19.",
    "reco03": "<b style='color:#a72225'>Avoid touching eyes, nose and mouth</b>",
    "reco03_why": "<b style='color:#a72225'>Why?</b><br>Hands touch many surfaces and can pick up viruses. Once contaminated, hands can transfer the virus to your eyes, nose or mouth. From there, the virus can enter your body and can make you sick.",
    "reco04": "<b style='color:#a72225'>Maintain social distancing</b><br>Maintain at least 1 metre (3 feet) distance between yourself and anyone who is coughing or sneezing.",
    "reco04_why": "<b style='color:#a72225'>Why?</b><br>When someone coughs or sneezes they spray small liquid droplets from their nose or mouth which may contain virus. If you are too close, you can breathe in the droplets, including the COVID-19 virus if the person coughing has the disease.",
    "reco05": "<b style='color:#a72225'>If you have fever, cough and difficulty breathing, seek medical care early</b><br>Stay home if you feel unwell. If you have a fever, cough and difficulty breathing, seek medical attention and call in advance. Follow the directions of your local health authority.",
    "reco05_why": "<b style='color:#a72225'>Why?</b><br>National and local authorities will have the most up to date information on the situation in your area. Calling in advance will allow your health care provider to quickly direct you to the right health facility. This will also protect you and help prevent spread of viruses and other infections.",
    "btnClose": "CLOSE",
    "copyright": "COPYRIGHT @ 2020 SURFERNET. ALL RIGHTS RESERVED."
  },
  "es": {
    "title": "Recomendaciones",
    "subtitle": "Medidas de protección básicas contra el Coronavirus (COVID-19) recomendadas por la Organización Mundial de la Salud.", 
    "reco01": "<b style='color:#a72225'>Lávese las manos frecuentemente</b><br>Lávese las manos con frecuencia con un desinfectante de manos a base de alcohol o con agua y jabón.",
    "reco01_why": "<b style='color:#a72225'>¿Por qué?</b><br>Lavarse las manos con un desinfectante a base de alcohol o con agua y jabón mata el virus si este está en sus manos.",
    "reco02": "<b style='color:#a72225'>Adopte medidas de higiene respiratoria</b><br>Al toser o estornudar, cúbrase la boca y la nariz con el codo flexionado o con un pañuelo; tire el pañuelo inmediatamente y lávese las manos con un desinfectante de manos a base de alcohol, o con agua y jabón.",
    "reco02_why": "<b style='color:#a72225'>¿Por qué?</b><br>Cuando alguien con una enfermedad respiratoria, como la infección por el COVID-19, tose o estornuda, proyecta pequeñas gotículas que contienen el virus. Si está demasiado cerca, puede inhalar el virus.",
    "reco03": "<b style='color:#a72225'>Evite tocarse los ojos, la nariz y la boca</b>",
    "reco03_why": "<b style='color:#a72225'>¿Por qué?</b><br>Las manos tocan muchas superficies que pueden estar contaminadas con el virus. Si se toca los ojos, la nariz o la boca con las manos contaminadas, puede transferir el virus de la superficie a sí mismo.",
    "reco04": "<b style='color:#a72225'>Mantenga el distanciamiento social</b><br>Mantenga al menos 1 metro (3 pies) de distancia entre usted y las demás personas, particularmente aquellas que tosan, estornuden y tengan fiebre.",
    "reco04_why": "<b style='color:#a72225'>¿Por qué?</b><br>Cuando alguien con una enfermedad respiratoria, como la infección por el COVID-19, tose o estornuda, proyecta pequeñas gotículas que contienen el virus. Si está demasiado cerca, puede inhalar el virus.",
    "reco05": "<b style='color:#a72225'>Si tiene fiebre, tos y dificultad para respirar, solicite atención médica a tiempo</b><br>Indique a su médico si ha viajado a una zona afectada por COVID-19, o si ha tenido un contacto cercano con alguien que haya estado en alguna de ellas y tenga síntomas respiratorios.",
    "reco05_why": "<b style='color:#a72225'>¿Por qué?</b><br>Siempre que tenga fiebre, tos y dificultad para respirar, es importante que busque atención médica de inmediato, ya que dichos síntomas pueden deberse a una infección respiratoria o a otra afección grave. Los síntomas respiratorios con fiebre pueden tener diversas causas, y dependiendo de sus antecedentes de viajes y circunstancias personales, el COVID-19 podría ser una de ellas.",
    "btnClose": "CERRAR",
    "copyright": "COPYRIGHT @ 2020 SURFERNET. ALL RIGHTS RESERVED." 
  }
}
</i18n>
