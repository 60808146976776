<template>
    <v-ons-page>
        <div class="background"></div>

        <div class="content">
            <div>
                <center>
                    <img
                        class="logoheader"
                        v-if="$root.$i18n.locale == 'es'"
                        src="/assets/images/mobile/logo_start_es.svg"
                    />
                    <img
                        class="logoheader"
                        v-else-if="$root.$i18n.locale == 'en'"
                        src="/assets/images/mobile/logo_start_us.svg"
                    />
                    <img
                        class="logoheader"
                        v-else
                        src="/assets/images/mobile/logo_start_es.svg"
                    />
                </center>
            </div>

            <div>
                <p
                    style="text-align: center; margin-top:40px; font-size: large;color: #a4ffff;"
                    v-html="$t('title')"
                ></p>
            </div>

            <center>
                <div
                    style="margin-top:40px; margin-left: auto; margin-right: auto;"
                >
                    <v-ons-row
                        style="text-align: center; margin-top:20px; max-width:300px;"
                    >
                        <v-ons-col width="90px"
                            ><img
                                src="/assets/images/mobile/icon02.png"
                                style="width:40%;"
                        /></v-ons-col>
                        <v-ons-col class="central_text">{{
                            $t("function03")
                        }}</v-ons-col>
                    </v-ons-row>

                    <v-ons-row
                        style="text-align: center; margin-top:20px; max-width:300px;"
                    >
                        <v-ons-col width="90px"
                            ><img
                                src="/assets/images/mobile/icon01.png"
                                style="width:40%;"
                        /></v-ons-col>
                        <v-ons-col class="central_text">{{
                            $t("function01")
                        }}</v-ons-col>
                    </v-ons-row>

                    <v-ons-row
                        style="text-align: center; margin-top:20px; max-width:300px;"
                    >
                        <v-ons-col width="90px"
                            ><img
                                src="/assets/images/mobile/icon03.png"
                                style="width:40%;"
                        /></v-ons-col>
                        <v-ons-col class="central_text">{{
                            $t("function02")
                        }}</v-ons-col>
                    </v-ons-row>
                </div>
            </center>
        </div>

        <div
            style="text-align: center; position:absolute; bottom: 45px; width: 100%;"
        >
            <v-ons-button
                id="btnEmpezar"
                style="padding: 10px 100px; background-color: #a4ffff !important; color:black;"
                @click="modalVisible = true"
            >
                {{ $t("btnStart") }}
            </v-ons-button>
        </div>
        <div
            style="text-align: center; position:absolute; bottom: 5px; width: 100%;"
        >
            <p style="color:white;font-size:x-small;">{{ $t("copyright") }}</p>
        </div>

        <!-- DIALOG PARA PEDIR OPTIN -->
        <v-ons-dialog
            :visible.sync="modalVisible"
            @preshow="
                optin_value = true;
                modalCancel = true;
            "
            @posthide="CheckOptin"
            cancelable
        >
            <p
                style="text-align: center; margin:30px 10px 10px 10px;font-family: raleway"
            ></p>
            <p style="margin:30px;" v-html="$t('popup_message02')" />
            <div style="margin:20px;">
                <label class="right">
                    <v-ons-checkbox
                        :input-id="'optin_component'"
                        v-model="optin_value"
                    />
                </label>
                <label
                    :for="'optin_component'"
                    class="center"
                    style="margin-left:5px; line-height: 1.5em;"
                >
                    {{ $t("accept") }}
                    <router-link to="terms">{{ $t("terms") }}</router-link>
                    {{ $t("and") }}
                    <router-link to="/privacy">{{ $t("privacy") }}</router-link>
                </label>
            </div>
            <center>
                <v-ons-button
                    style="margin-bottom: 10px;"
                    @click="
                        modalCancel = false;
                        modalVisible = false;
                    "
                    >{{ $t("btnContinue") }}</v-ons-button
                >
            </center>
        </v-ons-dialog>
    </v-ons-page>
</template>

<script>
export default {
    name: "Home",
    components: {},
    data() {
        return {
            modalVisible: false,
            optin_value: true,
            spdVisible: false,
            modalCancel: true
        };
    },
    methods: {
        CheckOptin: function(event) {
            if (!this.modalCancel && this.optin_value)
                this.$router.replace({ name: "AutoCheck" });
        }
    }
};
</script>

<style scoped>
.central_text {
    color: white;
    text-align: left;
}

.background {
    background-image: url(/assets/images/mobile/background.svg) !important;
    background-repeat: no-repeat !important;
    background-position-y: center !important;
    background-position-x: center !important;
    background-size: cover !important;
    background-color: rgba(46, 120, 142, 1) !important;
}

.page__content {
    font-family: raleway;
}

@media only screen and (max-width: 600px) {
    .logoheader {
        width: 65%;
        margin-top: 50px;
    }
}

@media only screen and (min-width: 600px) {
    .logoheader {
        width: 40%;
        margin-top: 50px;
    }
}
</style>

<i18n>
{
  "en": {
    "title": "Use the <b>PACCO</b> App to",
    "function01": "Evaluate your health condition according to your symptoms.",
    "function02": "Receive medical Recommendations and Notifications.",
    "function03": "Autocheck your symptoms regulary.",
    "accept": "I accept the",
    "terms": "Terms and Conditions",
    "and": "and the",
    "privacy": "Privacy Policy",
    "popup_message02": "The PACCO program requires the accurate gathering of personal and medical data related to your health condition.<br><br>Your private information will <u>never</u> be used outside the context of this program.<br><br>Fill out the information as precise as possible to avoid statistical interpretation errors.",
    "btnContinue": "CONTINUE",
    "btnStart": "START",
    "copyright": "COPYRIGHT @ 2020 SURFERNET. ALL RIGHTS RESERVED."
  },
  "es": {
    "title": "Utiliza el App <b>PACCO</b> para",
    "function01": "Evaluar tu estado de salud según tus síntomas.",
    "function02": "Recibir Recomendaciones y Notificaciones médicas.",
    "function03": "Automedición periódica de tus síntomas.",
    "accept": "Acepto los",
    "terms": "Términos y Condiciones de Uso",
    "and": "y",
    "privacy": "la Política de Privacidad",
    "popup_message02": "Este programa precisa de la captura de datos sensibles relativos a tu salud, para su correcto funcionamiento.<br><br>Tus datos <u>nunca</u> se usarán fuera del entorno de PACCO.<br><br>Rellena la información lo más precisamente posible para evitar errores de interpretación estadística.",
    "btnContinue": "CONTINUAR",
    "btnStart": "EMPEZAR",
    "copyright": "COPYRIGHT @ 2020 SURFERNET. ALL RIGHTS RESERVED."
  }
}
</i18n>
