export default ({
    state: {
        user: null,
        diagnostic: null,
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setDiagnostic(state, diagnostic) {
            state.diagnostic = diagnostic
        },
    },
    actions: {
        setUser({ commit }, user) {
            commit('setUser', user);
            axios.post("/api/patients", {
                patient: user
            }).then(response => {
                if (response.data) {
                    user.id = response.data.id;
                    commit('setUser', user);
                    console.log("AXIOS SUCCESS: Patient stored correctly in DB");
                }
            }).catch((err) => {
                console.error("AXIOS ERROR: ", err);
            });
        },
        setDiagnostic({ commit }, diagnostic) {
            commit('setDiagnostic', diagnostic);
            axios.post("/api/diagnostics", {
                diagnostic: diagnostic
            }).then(response => {
                console.log("AXIOS SUCCESS: Diagnostic stored correctly in DB");
            }).catch((err) => {
                console.error("AXIOS ERROR: ", err);
            });
        },
    },
    getters: {
        user: state => state.user,
        diagnostic: state => state.diagnostic,
    },
    modules: {}
});