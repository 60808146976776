var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-ons-splitter',[_c('v-ons-splitter-side',{attrs:{"swipeable":"","width":"220px","collapse":"","side":"left","open":_vm.openSide},on:{"update:open":function($event){_vm.openSide=$event}}},[_c('v-ons-page',[_c('v-ons-list',[_c('v-ons-list-item',{attrs:{"tappable":""},on:{"click":function($event){_vm.ClickOnMenu('Home');
                        _vm.openSide = false;}}},[_c('div',{staticClass:"center"},[_c('v-ons-icon',{staticStyle:{"color":"cadetblue"},attrs:{"icon":"fa-home"}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t("menu_home")))])],1)]),_vm._v(" "),_c('v-ons-list-item',{attrs:{"expandable":"","expanded":_vm.isExpanded},on:{"update:expanded":function($event){_vm.isExpanded=$event}}},[_c('div',[_c('v-ons-icon',{staticStyle:{"color":"brown"},attrs:{"icon":"fa-flag"}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t("menu_language")))])],1),_vm._v(" "),_c('div',{staticClass:"expandable-content"},[_c('div',{staticStyle:{"display":"flex"},on:{"click":function($event){return _vm.ChangeLocale('en')}}},[_c('country-flag',{staticStyle:{"margin-top":"-12px"},attrs:{"country":"us"}}),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"12px","margin-left":"12px"}},[_vm._v("\n                                "+_vm._s(_vm.$t("lang_English"))+"\n                            ")])],1),_vm._v(" "),_c('div',{staticStyle:{"display":"flex"},on:{"click":function($event){return _vm.ChangeLocale('es')}}},[_c('country-flag',{staticStyle:{"margin-top":"-12px"},attrs:{"country":"es"}}),_vm._v(" "),_c('div',{staticStyle:{"margin-top":"12px","margin-left":"12px"}},[_vm._v("\n                                "+_vm._s(_vm.$t("lang_Spanish"))+"\n                            ")])],1)])]),_vm._v(" "),_c('v-ons-list-item',{attrs:{"tappable":""},on:{"click":function($event){_vm.ClickOnMenu('Recommendations');
                        _vm.openSide = false;}}},[_c('div',{staticClass:"center"},[_c('v-ons-icon',{staticStyle:{"color":"darkorange"},attrs:{"icon":"fa-thumbs-up"}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t("menu_recommendations")))])],1)]),_vm._v(" "),_c('v-ons-list-item',{attrs:{"tappable":""},on:{"click":function($event){_vm.ClickOnMenu('Notifications');
                        _vm.openSide = false;}}},[_c('div',{staticClass:"center"},[_c('v-ons-icon',{staticStyle:{"color":"red"},attrs:{"icon":"fa-bell"}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t("menu_notifications")))])],1)]),_vm._v(" "),_c('v-ons-list-item',{attrs:{"tappable":""},on:{"click":function($event){_vm.ClickOnMenu('Share');
                        _vm.openSide = false;}}},[_c('div',{staticClass:"center"},[_c('v-ons-icon',{staticStyle:{"color":"blue"},attrs:{"icon":"fa-share-alt"}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t("menu_share")))])],1)]),_vm._v(" "),_c('v-ons-list-item',{attrs:{"tappable":""},on:{"click":function($event){_vm.ClickOnMenu('Credits');
                        _vm.openSide = false;}}},[_c('div',{staticClass:"center"},[_c('v-ons-icon',{staticStyle:{"color":"gold"},attrs:{"icon":"fa-medal"}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t("menu_credits")))])],1)]),_vm._v(" "),_c('v-ons-list-item',{attrs:{"tappable":""},on:{"click":function($event){_vm.ClickOnMenu('TermsAndConditions');
                        _vm.openSide = false;}}},[_c('div',{staticClass:"center"},[_c('v-ons-icon',{staticStyle:{"color":"green"},attrs:{"icon":"fa-file-invoice"}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t("menu_terms")))])],1)]),_vm._v(" "),_c('v-ons-list-item',{attrs:{"tappable":""},on:{"click":function($event){_vm.ClickOnMenu('PrivacyPolicy');
                        _vm.openSide = false;}}},[_c('div',{staticClass:"center"},[_c('v-ons-icon',{staticStyle:{"color":"grey"},attrs:{"icon":"fa-unlock-alt"}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t("menu_privacypolicy")))])],1)])],1)],1)],1),_vm._v(" "),_c('v-ons-splitter-content',[_c('v-ons-button',{staticStyle:{"z-index":"1000","position":"absolute","top":"0","left":"10px","background-color":"transparent"},on:{"click":function($event){_vm.openSide = true}}},[_c('v-ons-icon',{attrs:{"icon":"fa-bars"}})],1),_vm._v(" "),_c('router-view')],1),_vm._v(" "),_c('v-ons-toast',{attrs:{"visible":_vm.ios_a2hs,"animation":"ascend"},on:{"update:visible":function($event){_vm.ios_a2hs=$event}}},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t("install_ios01")))])]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t("install_ios02"))),_c('v-ons-icon',{attrs:{"icon":"md-open-in-browser"}}),_vm._v(_vm._s(_vm.$t("install_ios03"))+"\n        ")],1),_vm._v(" "),_c('button',{on:{"click":function($event){_vm.ios_a2hs = false}}},[_vm._v(_vm._s(_vm.$t("btnClose")))])]),_vm._v(" "),_c('v-ons-toast',{staticClass:"background_update",staticStyle:{"background-color":"white"},attrs:{"visible":_vm.update_available,"animation":"ascend"},on:{"update:visible":function($event){_vm.update_available=$event}}},[_c('p',[_vm._v("\n            "+_vm._s(_vm.$t("update01"))),_c('v-ons-button',{on:{"click":_vm.reload_update}},[_vm._v(_vm._s(_vm.$t("btnHere")))]),_vm._v(_vm._s(_vm.$t("update02"))+"\n        ")],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }