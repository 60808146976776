<template>
    <v-ons-page>
        <v-ons-toolbar class="header">
            <div class="center">
                <center><img src="/assets/images/mobile/logo.svg" /></center>
            </div>
        </v-ons-toolbar>

        <div class="background"></div>

        <div class="content, text_blue" style="margin:15px;">
            <h1 class="text_blue" v-html="$t('title')"></h1>

            <div
                class="text_blue"
                style="width:100%; margin-left:auto;margin-right:auto;"
            >
                <v-ons-row>
                    <p class="text_red" v-html="$t('sharebyemail')"></p>
                </v-ons-row>
                <v-ons-row style="margin-top:10px;">
                    <v-ons-input
                        class="bordered"
                        type="text"
                        float
                        v-bind:placeholder="$t('name_placeholder')"
                        v-model="from_name"
                        style="width: 100%;"
                    ></v-ons-input>
                </v-ons-row>
                <v-ons-row style="margin-top:20px;">
                    <textarea
                        class="textarea"
                        rows="3"
                        v-model="message"
                        v-bind:placeholder="$t('message_placeholder')"
                        style="width: 100%;"
                    ></textarea>
                </v-ons-row>
                <v-ons-row style="margin-top:20px;">
                    <v-ons-input
                        class="bordered"
                        type="email"
                        float
                        v-bind:placeholder="$t('email_placeholder')"
                        v-model="email"
                        style="width: 100%;"
                    ></v-ons-input>
                </v-ons-row>
                <v-ons-row style="justify-content: center;margin-top:20px;">
                    <v-ons-button style="" @click="SharebyEmail">{{
                        $t("btnShare")
                    }}</v-ons-button>
                </v-ons-row>
            </div>

            <div class="text_blue" style="width:100%;">
                <p class="text_red" v-html="$t('sharebysocialmedia')"></p>
                <div style="text-align:center;">
                    <v-ons-icon
                        icon="fa-facebook-square"
                        class="socialmediaicon"
                        @click="ShareFacebook"
                    ></v-ons-icon>
                    <v-ons-icon
                        icon="fa-twitter-square"
                        class="socialmediaicon"
                        @click="ShareTwitter"
                    ></v-ons-icon>
                    <v-ons-icon
                        icon="fa-linkedin"
                        class="socialmediaicon"
                        @click="ShareLinkedin"
                    ></v-ons-icon>
                </div>
            </div>

            <div style="text-align: center; width: 100%; margin-top:40px;">
                <v-ons-button
                    style="margin-bottom:30px; padding: 10px 80px; background-color: #a4ffff !important; color:black;"
                    @click="$router.replace({ name: 'Home' })"
                >
                    {{ $t("btnClose") }}
                </v-ons-button>
                <p style="color:black;font-size:x-small;">
                    {{ $t("copyright") }}
                </p>
            </div>
        </div>

        <v-ons-toast :visible.sync="showErrors" animation="ascend">
            <ul>
                <li v-for="error in errors" v-bind:key="error">
                    {{ error }}
                </li>
            </ul>
            <button @click="showErrors = false">
                {{ $t("btnClose") }}
            </button>
        </v-ons-toast>

        <v-ons-modal :visible="modalVisible" @click="modalVisible = false">
            <p style="text-align: center">
                {{ $t("popup_message") }}
                <v-ons-icon icon="fa-spinner" spin></v-ons-icon> <br /><br />
            </p>
        </v-ons-modal>
    </v-ons-page>
</template>

<script>
export default {
    name: "Share",
    data() {
        return {
            errors: [],
            showErrors: false,
            email: "",
            message: "",
            from_name: "",
            modalVisible: false
        };
    },
    methods: {
        ShareFacebook() {
            window.open(this.$t("shareURLFacebook"), "_blank");
        },
        ShareTwitter() {
            window.open(this.$t("shareURLTwitter"), "_blank");
        },
        ShareLinkedin() {
            window.open(this.$t("shareURLLinkedin"), "_blank");
        },
        SharebyEmail() {
            this.errors = [];
            if (!this.email) this.errors.push(this.$t("error_emailMandatory"));
            else if (!this.validEmail(this.email))
                this.errors.push(this.$t("error_emailNotValid"));

            if (this.errors.length > 0) this.showErrors = true;
            else {
                this.modalVisible = true;
                axios
                    .post("/api/notifications/share", {
                        message: this.message,
                        from_name: this.from_name,
                        email: this.email,
                        locale: this.$root._i18n.locale
                    })
                    .then(result => {
                        this.modalVisible = false;
                        console.log("Aplicacion Shared Correctamente");
                        this.$router.replace({ name: "Home" });
                    })
                    .catch(err => {
                        this.modalVisible = false;
                        console.log("Error al realizar el share: ", err);
                    });
            }
        },
        validEmail: function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    }
};
</script>

<style scoped>
.text_blue {
    color: #020646;
    font-family: raleway !important;
}

.text_red {
    color: #a92526 !important;
    font-family: raleway !important;
    font-weight: bold;
}

.socialmediaicon {
    color: blue;
    font-size: 2.5em;
    margin-left: 10px;
    margin-right: 10px;
}
</style>

<i18n>
{
  "en": {
    "title": "Share this App with your Friends and Family",
    "sharebyemail": "Share via e-Mail",
    "sharebysocialmedia": "Share on Social Media",
    "shareURLFacebook": "https://www.facebook.com/sharer.php?u=https%3A%2F%2Fpacco.surfernet.com%2F",
    "shareURLTwitter": "https://twitter.com/intent/tweet?url=https%3A%2F%2Fpacco.surfernet.com%2F&text=PACCO%20Platform%20to%20Autocheck%20and%20Control%20the%20COronavirus&hashtags=covid-19,surfernet",
    "shareURLLinkedin": "https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fpacco.surfernet.com%2F&title=PACCO&summary=PACCO%20Platform%20to%20Autocheck%20and%20Control%20the%20COronavirus&source=Surfernet",
    "name_placeholder": "Your Name & Last Name",
    "message_placeholder": "Message...",
    "email_placeholder": "Recipent's e-Mail",
    "btnShare": "Share",
    "btnClose": "CLOSE",
    "error_emailMandatory": "E-Mail field cannot be blank.",
    "error_emailNotValid": "E-Mail field must be in a valid format.",
    "popup_message": "Sending Notification, please wait.",
    "copyright": "COPYRIGHT @ 2020 SURFERNET. ALL RIGHTS RESERVED."
  },
  "es": {
    "title": "Comparte este App con tus amigos y familiares",
    "sharebyemail": "Comparte a través de e-Mail",
    "sharebysocialmedia": "Comparte en tus Redes Sociales",
    "shareURLFacebook": "https://www.facebook.com/sharer.php?u=https%3A%2F%2Fpacco.surfernet.com%2F",
    "shareURLTwitter": "https://twitter.com/intent/tweet?url=https%3A%2F%2Fpacco.surfernet.com%2F&text=PACCO%20Platforma%20de%20Automedicion%20y%20Control%20del%20COronavirus&hashtags=covid-19,surfernet",
    "shareURLLinkedin": "https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fpacco.surfernet.com%2F&title=PACCO%20Platforma%20de%20Automedicion%20y%20Control%20del%20COronavirus&source=Surfernet",
    "name_placeholder": "Tu Nombre y Apellidos",
    "message_placeholder": "Mensaje...",
    "email_placeholder": "e-Mail del Destinatario",
    "btnShare": "Compartir",
    "btnClose": "CERRAR",
    "error_emailMandatory": "El campo E-Mail es obligatorio.",
    "error_emailNotValid": "El campo E-Mail debe ser contener una dirección de válida.",    
    "popup_message": "Enviando el mensaje, por favor espere.",
    "copyright": "COPYRIGHT @ 2020 SURFERNET. ALL RIGHTS RESERVED." 
  }
}
</i18n>
