<template>
    <v-ons-page>
        <v-ons-toolbar class="header">
            <div class="center">
                <center><img src="/assets/images/mobile/logo.svg" /></center>
            </div>
        </v-ons-toolbar>

        <div class="background"></div>

        <div class="content" style="background-color:white !important;">
            <h1 class="text_blue" style="padding: 0px 15px;">
                {{ $t("title") }}
            </h1>
            <p class="text_blue" style="padding: 0px 15px;">
                {{ $t("subtitle") }}
            </p>

            <v-ons-list>
                <!-- DIFICULTADES PARA RESPIRAR -->
                <v-ons-list-item>
                    <div
                        class="text_blue bold"
                        style="width:100%; padding:0px 10px;"
                    >
                        <p>{{ $t("respiratory_difficulties") }}</p>
                        <v-ons-row>
                            <v-ons-col width="25%" style="min-width:145px;"
                                ><img
                                    src="/assets/images/mobile/q1_icon.png"
                                    style="width:50px;"
                            /></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:right;"
                                >{{ $t("no") }}</v-ons-col
                            >
                            <v-ons-col
                                width="25%"
                                class="vertical_centered"
                                style="text-align:center;"
                                ><v-ons-switch
                                    v-model="switchFaltaAire"
                                ></v-ons-switch
                            ></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:left;"
                                >{{ $t("yes") }}</v-ons-col
                            >
                        </v-ons-row>
                    </div>
                </v-ons-list-item>
                <!-- ENFERMEDADES ADICIONALES -->
                <v-ons-list-item>
                    <!-- <v-ons-list-item v-show="user!=null && user.age>=60"> -->
                    <div
                        class="text_blue bold"
                        style="width:100%; padding:0px 10px;"
                    >
                        <p>{{ $t("previous_illness") }}</p>
                        <v-ons-row>
                            <v-ons-col width="25%" style="min-width:145px;"
                                ><img
                                    src="/assets/images/mobile/q12_icon.png"
                                    style="width:20px;"
                                />&nbsp;<span style="font-size:small;">{{
                                    $t("previous_illness_hypertension")
                                }}</span></v-ons-col
                            >
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:right;"
                                >{{ $t("no") }}</v-ons-col
                            >
                            <v-ons-col
                                width="25%"
                                class="vertical_centered"
                                style="text-align:center;"
                                ><v-ons-switch
                                    v-model="switchHipertension"
                                ></v-ons-switch
                            ></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:left;"
                                >{{ $t("yes") }}</v-ons-col
                            >
                        </v-ons-row>

                        <v-ons-row>
                            <v-ons-col width="25%" style="min-width:145px;"
                                ><img
                                    src="/assets/images/mobile/q13_icon.png"
                                    style="width:20px;"
                                />&nbsp;<span style="font-size:small;">{{
                                    $t("previous_illness_diabetes")
                                }}</span></v-ons-col
                            >
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:right;"
                                >{{ $t("no") }}</v-ons-col
                            >
                            <v-ons-col
                                width="25%"
                                class="vertical_centered"
                                style="text-align:center;"
                                ><v-ons-switch
                                    v-model="switchDiabetes"
                                ></v-ons-switch
                            ></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:left;"
                                >{{ $t("yes") }}</v-ons-col
                            >
                        </v-ons-row>

                        <v-ons-row>
                            <v-ons-col width="25%" style="min-width:145px;"
                                ><img
                                    src="/assets/images/mobile/q14_icon.png"
                                    style="width:20px;"
                                />&nbsp;<span style="font-size:small;">{{
                                    $t("previous_illness_respi")
                                }}</span></v-ons-col
                            >
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:right;"
                                >{{ $t("no") }}</v-ons-col
                            >
                            <v-ons-col
                                width="25%"
                                class="vertical_centered"
                                style="text-align:center;"
                                ><v-ons-switch
                                    v-model="switchEnfPulmonar"
                                ></v-ons-switch
                            ></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:left;"
                                >{{ $t("yes") }}</v-ons-col
                            >
                        </v-ons-row>
                    </div>
                </v-ons-list-item>
                <!-- FIEBRE -->
                <v-ons-list-item>
                    <div
                        class="text_blue bold"
                        style="width:100%; padding:0px 10px;"
                    >
                        <p>
                            {{ $t("fever") }}
                            <span style="margin-left:20px;" v-if="switchFiebre">
                                <span
                                    :style="
                                        showingTempInCelsius
                                            ? { 'text-decoration': 'underline' }
                                            : { 'text-decoration': 'none' }
                                    "
                                    @click="showingTempInCelsius = true"
                                    >ºC</span
                                >
                                |
                                <span
                                    :style="
                                        !showingTempInCelsius
                                            ? { 'text-decoration': 'underline' }
                                            : { 'text-decoration': 'none' }
                                    "
                                    @click="showingTempInCelsius = false"
                                    >ºF</span
                                >
                            </span>
                        </p>
                        <v-ons-row>
                            <v-ons-col width="25%" style="min-width:145px;"
                                ><img
                                    src="/assets/images/mobile/q2_icon.png"
                                    style="width:50px;"
                            /></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:right;"
                                >{{ $t("no") }}</v-ons-col
                            >
                            <v-ons-col
                                width="25%"
                                class="vertical_centered"
                                style="text-align:center;"
                                ><v-ons-switch
                                    v-model="switchFiebre"
                                ></v-ons-switch
                            ></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:left;"
                                >{{ $t("yes") }}</v-ons-col
                            >
                        </v-ons-row>

                        <div v-if="switchFiebre">
                            <!-- SI EL LOCALE ESTA EN EN PONEMOS LA TEMPERATURA EN FAHRENHEIT -->
                            <div v-if="!showingTempInCelsius">
                                <center>
                                    <span
                                        >{{ $t("temperature") }} =
                                        {{ temperature_fahrenheit }}
                                        {{ $t("fahrenheit") }}</span
                                    >
                                </center>
                                <v-ons-row>
                                    <v-ons-col
                                        width="65px"
                                        style="text-align: center; line-height: 31px;"
                                        @click="
                                            temperature > 37.5
                                                ? (temperature =
                                                      temperature - 0.5)
                                                : (temperature = 37.5)
                                        "
                                    >
                                        <v-ons-icon
                                            icon="fa-temperature-low"
                                        ></v-ons-icon>
                                    </v-ons-col>
                                    <v-ons-col>
                                        <v-ons-range
                                            v-model.number="
                                                temperature_fahrenheit
                                            "
                                            style="width: 100%;"
                                            min="100"
                                            max="113"
                                            step="1"
                                        ></v-ons-range>
                                    </v-ons-col>
                                    <v-ons-col
                                        width="65px"
                                        style="text-align: center; line-height: 31px;"
                                        @click="
                                            temperature < 45
                                                ? (temperature =
                                                      temperature + 0.5)
                                                : (temperature = 45)
                                        "
                                    >
                                        <v-ons-icon
                                            icon="fa-temperature-high"
                                        ></v-ons-icon>
                                    </v-ons-col>
                                </v-ons-row>
                            </div>
                            <!-- ENCUALQUIER OTRO CASO EN CELSIUS -->
                            <div v-else>
                                <center>
                                    <span
                                        >{{ $t("temperature") }} =
                                        {{ temperature }}
                                        {{ $t("degree") }}</span
                                    >
                                </center>
                                <v-ons-row>
                                    <v-ons-col
                                        width="65px"
                                        style="text-align: center; line-height: 31px;"
                                        @click="
                                            temperature > 37.5
                                                ? (temperature =
                                                      temperature - 0.5)
                                                : (temperature = 37.5)
                                        "
                                    >
                                        <v-ons-icon
                                            icon="fa-temperature-low"
                                        ></v-ons-icon>
                                    </v-ons-col>
                                    <v-ons-col>
                                        <v-ons-range
                                            v-model="temperature"
                                            style="width: 100%;"
                                            min="37.5"
                                            max="45"
                                            step="0.1"
                                        ></v-ons-range>
                                    </v-ons-col>
                                    <v-ons-col
                                        width="65px"
                                        style="text-align: center; line-height: 31px;"
                                        @click="
                                            temperature < 45
                                                ? (temperature =
                                                      temperature + 0.5)
                                                : (temperature = 45)
                                        "
                                    >
                                        <v-ons-icon
                                            icon="fa-temperature-high"
                                        ></v-ons-icon>
                                    </v-ons-col>
                                </v-ons-row>
                            </div>
                        </div>

                        <div v-if="switchFiebre">
                            <p>{{ $t("howmanydaysfever") }}</p>
                            <center>
                                <span v-if="Number(dayswithFever) < 16"
                                    >{{ $t("days") }} =
                                    {{ dayswithFever }}</span
                                ><span v-else>{{ $t("more_15_days") }}</span>
                            </center>
                            <v-ons-row>
                                <v-ons-col
                                    width="65px"
                                    style="text-align: center; line-height: 31px;"
                                    @click="
                                        dayswithFever > 1
                                            ? dayswithFever--
                                            : (dayswithFever = 1)
                                    "
                                >
                                    <v-ons-icon
                                        icon="fa-minus-square"
                                    ></v-ons-icon>
                                </v-ons-col>
                                <v-ons-col>
                                    <v-ons-range
                                        v-model.number="dayswithFever"
                                        style="width: 100%;"
                                        min="1"
                                        max="16"
                                        step="1"
                                    ></v-ons-range>
                                </v-ons-col>
                                <v-ons-col
                                    width="65px"
                                    style="text-align: center; line-height: 31px;"
                                    @click="
                                        dayswithFever < 16
                                            ? dayswithFever++
                                            : (dayswithFever = 16)
                                    "
                                >
                                    <v-ons-icon
                                        icon="fa-plus-square"
                                    ></v-ons-icon>
                                </v-ons-col>
                            </v-ons-row>
                        </div>
                    </div>
                </v-ons-list-item>
                <!-- TOS -->
                <v-ons-list-item>
                    <div
                        class="text_blue bold"
                        style="width:100%; padding:0px 10px;"
                    >
                        <p>{{ $t("cough") }}</p>
                        <v-ons-row>
                            <v-ons-col width="25%" style="min-width:145px;"
                                ><img
                                    src="/assets/images/mobile/q3_icon.png"
                                    style="width:50px;"
                            /></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:right;"
                                >{{ $t("no") }}</v-ons-col
                            >
                            <v-ons-col
                                width="25%"
                                class="vertical_centered"
                                style="text-align:center;"
                                ><v-ons-switch
                                    v-model="switchTos"
                                ></v-ons-switch
                            ></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:left;"
                                >{{ $t("yes") }}</v-ons-col
                            >
                        </v-ons-row>
                    </div>
                </v-ons-list-item>
                <!-- CONTACTO -->
                <v-ons-list-item>
                    <div
                        class="text_blue bold"
                        style="width:100%; padding:0px 10px;"
                    >
                        <p>{{ $t("contact") }}</p>
                        <v-ons-row>
                            <v-ons-col width="25%" style="min-width:145px;"
                                ><img
                                    src="/assets/images/mobile/q4_icon.png"
                                    style="width:50px;"
                            /></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:right;"
                                >{{ $t("no") }}</v-ons-col
                            >
                            <v-ons-col
                                width="25%"
                                class="vertical_centered"
                                style="text-align:center;"
                                ><v-ons-switch
                                    v-model="switchContacto"
                                ></v-ons-switch
                            ></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:left;"
                                >{{ $t("yes") }}</v-ons-col
                            >
                        </v-ons-row>
                    </div>
                </v-ons-list-item>
                <!-- DOLOR MUSCULAR -->
                <v-ons-list-item>
                    <div
                        class="text_blue bold"
                        style="width:100%; padding:0px 10px;"
                    >
                        <p>{{ $t("muscular_pain") }}</p>
                        <v-ons-row>
                            <v-ons-col width="25%" style="min-width:145px;"
                                ><img
                                    src="/assets/images/mobile/q6_icon.png"
                                    style="width:50px;"
                            /></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:right;"
                                >{{ $t("no") }}</v-ons-col
                            >
                            <v-ons-col
                                width="25%"
                                class="vertical_centered"
                                style="text-align:center;"
                                ><v-ons-switch
                                    v-model="switchDolorMuscular"
                                ></v-ons-switch
                            ></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:left;"
                                >{{ $t("yes") }}</v-ons-col
                            >
                        </v-ons-row>
                    </div>
                </v-ons-list-item>
                <!-- PERDIDA DE APETITO -->
                <v-ons-list-item>
                    <div
                        class="text_blue bold"
                        style="width:100%; padding:0px 10px;"
                    >
                        <p>{{ $t("lost_appetite") }}</p>
                        <v-ons-row>
                            <v-ons-col width="25%" style="min-width:145px;"
                                ><img
                                    src="/assets/images/mobile/q9_icon.png"
                                    style="width:50px;"
                            /></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:right;"
                                >{{ $t("no") }}</v-ons-col
                            >
                            <v-ons-col
                                width="25%"
                                class="vertical_centered"
                                style="text-align:center;"
                                ><v-ons-switch
                                    v-model="switchApetito"
                                ></v-ons-switch
                            ></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:left;"
                                >{{ $t("yes") }}</v-ons-col
                            >
                        </v-ons-row>
                    </div>
                </v-ons-list-item>
                <!-- VOMITOS O DIARREA -->
                <v-ons-list-item>
                    <div
                        class="text_blue bold"
                        style="width:100%; padding:0px 10px;"
                    >
                        <p>{{ $t("vomiting_diarrhea") }}</p>
                        <v-ons-row>
                            <v-ons-col width="25%" style="min-width:145px;"
                                ><img
                                    src="/assets/images/mobile/q7_icon.png"
                                    style="width:50px;"
                            /></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:right;"
                                >{{ $t("no") }}</v-ons-col
                            >
                            <v-ons-col
                                width="25%"
                                class="vertical_centered"
                                style="text-align:center;"
                                ><v-ons-switch
                                    v-model="switchGastro"
                                ></v-ons-switch
                            ></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:left;"
                                >{{ $t("yes") }}</v-ons-col
                            >
                        </v-ons-row>
                    </div>
                </v-ons-list-item>
                <!-- PERDIDA DE OLFATO -->
                <v-ons-list-item>
                    <div
                        class="text_blue bold"
                        style="width:100%; padding:0px 10px;"
                    >
                        <p>{{ $t("lost_olfato") }}</p>
                        <v-ons-row>
                            <v-ons-col width="25%" style="min-width:145px;"
                                ><img
                                    src="/assets/images/mobile/q10_icon.png"
                                    style="width:50px;"
                            /></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:right;"
                                >{{ $t("no") }}</v-ons-col
                            >
                            <v-ons-col
                                width="25%"
                                class="vertical_centered"
                                style="text-align:center;"
                                ><v-ons-switch
                                    v-model="switchOlfato"
                                ></v-ons-switch
                            ></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:left;"
                                >{{ $t("yes") }}</v-ons-col
                            >
                        </v-ons-row>
                    </div>
                </v-ons-list-item>
                <!-- CEFALEA -->
                <v-ons-list-item>
                    <div
                        class="text_blue bold"
                        style="width:100%; padding:0px 10px;"
                    >
                        <p>{{ $t("headache") }}</p>
                        <v-ons-row>
                            <v-ons-col width="25%" style="min-width:145px;"
                                ><img
                                    src="/assets/images/mobile/q15_icon.png"
                                    style="width:50px;"
                            /></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:right;"
                                >{{ $t("no") }}</v-ons-col
                            >
                            <v-ons-col
                                width="25%"
                                class="vertical_centered"
                                style="text-align:center;"
                                ><v-ons-switch
                                    v-model="switchHeadache"
                                ></v-ons-switch
                            ></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:left;"
                                >{{ $t("yes") }}</v-ons-col
                            >
                        </v-ons-row>
                    </div>
                </v-ons-list-item>
                <!-- VARIOS DIAS CON SINTOMAS -->
                <v-ons-list-item>
                    <div
                        class="text_blue bold"
                        style="width:100%; padding:0px 10px;"
                    >
                        <p>{{ $t("days_with_synthomas") }}</p>
                        <v-ons-row>
                            <v-ons-col width="25%" style="min-width:145px;"
                                ><img
                                    src="/assets/images/mobile/q8_icon.png"
                                    style="width:50px;"
                            /></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:right;"
                                >{{ $t("no") }}</v-ons-col
                            >
                            <v-ons-col
                                width="25%"
                                class="vertical_centered"
                                style="text-align:center;"
                                ><v-ons-switch
                                    v-model="switchSeveralDaySymptom"
                                ></v-ons-switch
                            ></v-ons-col>
                            <v-ons-col
                                width="10%"
                                class="vertical_centered"
                                style="text-align:left;"
                                >{{ $t("yes") }}</v-ons-col
                            >
                        </v-ons-row>

                        <div v-if="switchSeveralDaySymptom">
                            <p>{{ $t("how_many") }}</p>
                            <center>
                                <span v-if="Number(dayswithSymptom) < 16"
                                    >{{ $t("days") }} =
                                    {{ dayswithSymptom }}</span
                                ><span v-else>{{ $t("more_15_days") }}</span>
                            </center>
                            <v-ons-row>
                                <v-ons-col
                                    width="65px"
                                    style="text-align: center; line-height: 31px;"
                                    @click="
                                        dayswithSymptom > 1
                                            ? dayswithSymptom--
                                            : (dayswithSymptom = 1)
                                    "
                                >
                                    <v-ons-icon
                                        icon="fa-minus-square"
                                    ></v-ons-icon>
                                </v-ons-col>
                                <v-ons-col>
                                    <v-ons-range
                                        v-model.number="dayswithSymptom"
                                        style="width: 100%;"
                                        :min="1"
                                        max="16"
                                        step="1"
                                    ></v-ons-range>
                                </v-ons-col>
                                <v-ons-col
                                    width="65px"
                                    style="text-align: center; line-height: 31px;"
                                    @click="
                                        dayswithSymptom < 16
                                            ? dayswithSymptom++
                                            : (dayswithSymptom = 16)
                                    "
                                >
                                    <v-ons-icon
                                        icon="fa-plus-square"
                                    ></v-ons-icon>
                                </v-ons-col>
                            </v-ons-row>
                        </div>
                    </div>
                </v-ons-list-item>
            </v-ons-list>

            <center>
                <v-ons-button
                    id="btnContinuar"
                    style="margin-top:50px; margin-bottom:50px; padding: 10px 100px; background-color: #a4ffff !important; color:black;"
                    @click="Continue_click"
                >
                    {{ $t("btnContinue") }}
                </v-ons-button>
            </center>
        </div>
    </v-ons-page>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "AutoDiagnostic",
    data() {
        return {
            switchEnfPulmonar: false,
            switchDiabetes: false,
            switchHipertension: false,
            switchFaltaAire: false,
            switchFiebre: false,
            dayswithFever: 1,
            switchHeadache: false,
            temperature: 37.5,
            switchTos: false,
            switchContacto: false,
            switchDolorMuscular: false,
            switchGastro: false,
            switchApetito: false,
            switchOlfato: false,
            switchSeveralDaySymptom: false,
            dayswithSymptom: 1,
            ratingValue: 0,
            showingTempInCelsius: true
        };
    },
    mounted() {
        if (localStorage.hascardiodisease)
            this.switchHipertension = /true/i.test(
                localStorage.hascardiodisease
            );
        if (localStorage.hasdiabetesdisease)
            this.switchDiabetes = /true/i.test(localStorage.hasdiabetesdisease);
        if (localStorage.haspulmonardisease)
            this.switchEnfPulmonar = /true/i.test(
                localStorage.haspulmonardisease
            );
    },
    watch: {
        switchHipertension(value) {
            localStorage.hascardiodisease = value;
        },
        switchDiabetes(value) {
            localStorage.hasdiabetesdisease = value;
        },
        switchEnfPulmonar(value) {
            localStorage.haspulmonardisease = value;
        }
    },
    computed: {
        ...mapGetters(["user"]),
        temperature_fahrenheit: {
            get: function() {
                var result = (this.temperature * 9) / 5 + 32;
                return Math.round(result);
            },
            set: function(newValue) {
                var newTemp = (5 / 9) * (newValue - 32);
                this.temperature = Math.round(newTemp);
            }
        }
    },
    methods: {
        ...mapActions(["setDiagnostic"]),
        Continue_click: function(event) {
            this.ratingValue = this.CalculateRating();

            this.setDiagnostic({
                previousDisease: {
                    pulmonar: this.switchEnfPulmonar,
                    diabetes: this.switchDiabetes,
                    hypertension: this.switchHipertension
                },
                smoker: this.switchFumador,
                noAir: this.switchFaltaAire,
                fever: {
                    status: this.switchFiebre,
                    temp: this.temperature,
                    days: this.dayswithFever
                },
                cough: this.switchTos,
                contact: this.switchContacto,
                muscularPain: this.switchDolorMuscular,
                gastro: this.switchGastro,
                lostAppetite: this.switchApetito,
                lostSmell: this.switchOlfato,
                daysWithSymptoms: {
                    status: this.switchSeveralDaySymptom,
                    num_days: this.dayswithSymptom
                },
                headache: this.switchHeadache,
                rating: { version: "1.2", value: this.ratingValue },
                gps_coordinates: this.user.gps_coordinates,
                patient_id: this.user.id
            });
            this.$emit("change-Page", "results");
        },

        CalculateRating: function() {
            var value = 0;
            var sintomas = [];
            /* 1.- DIFICULTAD RESPIRATORIA */
            if (this.switchFaltaAire) {
                value += 100;
            }
            /* 3.- DOLOR DE CABEZA MAS DE 4 DIAS */
            if (this.switchHeadache && this.dayswithSymptom > 4) {
                value += 4;
                sintomas.push(3);
            }
            /* 5.- FIEBRE DURANTE MAS DE 4 DIAS */
            if (this.switchFiebre && this.dayswithFever > 4) {
                value += 35;
                sintomas.push(5);
            }
            /* 6.- TOS SECA MUY PERSISTENTE MAS DE 4 DIAS */

            if (this.switchTos && this.dayswithSymptom > 4) {
                value += 8;
                sintomas.push(6);
            }
            /* 7.- DOLORES MUSCULARES MAS DE 4 DIAS */

            if (this.switchDolorMuscular && this.dayswithSymptom > 4) {
                value += 8;
                sintomas.push(7);
            }
            /* 8.- FALTA DE APETITO MAS DE 4 DIAS */

            if (this.switchApetito && this.dayswithSymptom > 4) {
                value += 5;
                sintomas.push(8);
            }
            /* 9.- PERDIDA DE OLFATO MAS DE 3 DIAS */

            if (this.switchOlfato && this.dayswithSymptom > 3) {
                value += 4;
                sintomas.push(9);
            }
            /* 10.- VOMITOS Y DIARREA MAS DE 3 DIAS */

            if (this.switchGastro && this.dayswithSymptom > 3) {
                value += 9;
                sintomas.push(10);
            }

            /* 2.- MAYOR DE 60 y 3 COMORBILIDADES Y ALGUN SINTOMA DEL 3 AL 10*/
            if (sintomas.length >= 1) {
                if (this.user.age >= 60) {
                    if (
                        this.switchEnfPulmonar &&
                        this.switchDiabetes &&
                        this.switchHipertension
                    ) {
                        value += 100;
                        sintomas.push(2);
                    }
                } else {
                    /* 1 COMORBILIDAD y SINTOMAS DEL 3 AL 10*/
                    if (
                        (this.switchEnfPulmonar &&
                            !this.switchDiabetes &&
                            !this.switchHipertension) ||
                        (!this.switchEnfPulmonar &&
                            this.switchDiabetes &&
                            !this.switchHipertension) ||
                        (!this.switchEnfPulmonar &&
                            !this.switchDiabetes &&
                            this.switchHipertension)
                    ) {
                        value += 10;
                        sintomas.push(2);
                    } else if (
                        /* 2 COMORBILIDADES */
                        (this.switchEnfPulmonar &&
                            this.switchDiabetes &&
                            !this.switchHipertension) ||
                        (!this.switchEnfPulmonar &&
                            this.switchDiabetes &&
                            this.switchHipertension) ||
                        (this.switchEnfPulmonar &&
                            !this.switchDiabetes &&
                            this.switchHipertension)
                    ) {
                        value += 25;
                        sintomas.push(2);
                    } else if (
                        /* 3 COMORBILIDADES */
                        this.switchEnfPulmonar &&
                        this.switchDiabetes &&
                        this.switchHipertension
                    ) {
                        value += 35;
                        sintomas.push(2);
                    }
                }
            }

            /* 4.- FIEBRE MENOS 4 DIAS Y ALGUN OTRO SINTOMA O COMORBILIDAD*/
            if (
                this.switchFiebre &&
                this.dayswithFever <= 4 &&
                sintomas.length >= 1
            ) {
                value += 15;
                sintomas.push(4);
            }

            /* CONTACTO POSITIVO Y ALGUN SINTOMA DEL 2 AL 10 */

            if (this.switchContacto && sintomas.length > 1) value += 7;
            return value;
        }
    }
};
</script>

<style scoped>
.text_blue {
    color: #033f70;
    font-family: raleway !important;
}

.vertical_centered {
    margin-top: auto;
    margin-bottom: auto;
}

.bold {
    font-weight: bold;
}
</style>

<i18n>
{
  "en": {
    "title": "Which symptoms do you have?",
    "subtitle": "Please answer the following questions to complete the autocheck process.",
    "previous_illness": "Do you have a previous disease?",
    "previous_illness_respi": "Respiratory",
    "previous_illness_diabetes": "Diabetes",
    "previous_illness_hypertension": "Cardiovascular",
    "smoker": "Do you smoke?",
    "respiratory_difficulties": " Do you have difficulties in breathing, pain or chest pressure NOT related to a previous respiratory disease?",
    "fever": "Do you have fever?",
    "howmanydaysfever": "How many days?",
    "temperature": "Temperature",
    "degree": "ºC",
    "fahrenheit": "ºF",
    "cough": "Do you have a dry and persistent cough?",
    "contact": "Have you been in close contact with someone diagnosed with COVID-19?",
    "muscular_pain": "Do you have muscle pain?",
    "lost_appetite": "Have you noticed a loss of appetite?",
    "vomiting_diarrhea": "Do you have diarrhea or vomiting?",
    "lost_olfato": "Have you noticed a loss of smell/taste?",
    "days_with_synthomas": "Have you been with these symptoms for several days?",
    "more_15_days": "More than 15 days",
    "days": "Days",
    "how_many": "How many days?",
    "headache": "Have you experienced headaches?",
    "no": "No",
    "yes": "Yes",
    "btnContinue": "FINISH"
  },
  "es": {
    "title": "¿Qué Síntomas tienes?",
    "subtitle": "Responde a las siguientes preguntas para completar tu automedición.",
    "previous_illness": "¿Tienes alguna enfermedad previa?",
    "previous_illness_respi": "Respiratorias",
    "previous_illness_diabetes": "Diabetes",
    "previous_illness_hypertension": "Cardiovasculares",
    "smoker": "¿Eres fumador?",
    "respiratory_difficulties": "¿Tienes dificultades para respirar, dolor, o presión en el pecho que NO sean debidas a una enfermedad respiratoria pre-existente?",
    "fever": "¿Tienes fiebre?",
    "howmanydaysfever": "¿Cuántos días?",
    "temperature": "Temperatura",
    "degree": "ºC",
    "fahrenheit": "ºF",
    "cough": "¿Tienes tos seca y persistente?",
    "contact": "¿Has tenido contacto cercano con alguna persona diagnosticada con el COVID-19?",
    "mucus": "¿Tienes mocos?",
    "muscular_pain": "¿Tienes dolor muscular?",
    "lost_appetite": "¿Has notado falta de apetito?",
    "vomiting_diarrhea": "¿Tienes vómitos o diarrea?",
    "lost_olfato": "¿Has notado pérdida de olfato/gusto?",
    "days_with_synthomas": "¿Llevas varios días con estos síntomas?",
    "more_15_days": "Más de 15 días",
    "days": "Días",
    "how_many": "¿Cuántos días?",
    "headache": "¿Has tenido dolores de cabeza?",
    "no": "No",
    "yes": "Sí",
    "btnContinue": "FINALIZAR"
  }
}
</i18n>
