var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-ons-page',[_c('div',{staticClass:"background"}),_vm._v(" "),_c('div',{staticClass:"content"},[_c('div',{staticClass:"center"},[_c('center',[_c('img',{staticClass:"logo",attrs:{"src":"/assets/images/mobile/logo.svg"}})])],1),_vm._v(" "),_c('div',{staticClass:"center"},[(this.diagnostic.rating.value == 0)?_c('center',[_c('img',{staticClass:"main_logo",attrs:{"src":"/assets/images/mobile/results01.svg"}})]):_vm._e(),_vm._v(" "),(
                    this.diagnostic.rating.value > 1 &&
                        this.diagnostic.rating.value <= 50
                )?_c('center',[_c('img',{staticClass:"main_logo",attrs:{"src":"/assets/images/mobile/results02.svg"}})]):_vm._e(),_vm._v(" "),(
                    this.diagnostic.rating.value > 51 &&
                        this.diagnostic.rating.value <= 70
                )?_c('center',[_c('img',{staticClass:"main_logo",attrs:{"src":"/assets/images/mobile/results02.svg"}})]):_vm._e(),_vm._v(" "),(this.diagnostic.rating.value > 70)?_c('center',[_c('img',{staticClass:"main_logo",attrs:{"src":"/assets/images/mobile/results03.svg"}})]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"center",staticStyle:{"margin":"0px 40px","color":"white","font-size":"large","font-weight":"bold"}},[_c('center',[(this.diagnostic.rating.value == 0)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('title01'))}}):_vm._e(),_vm._v(" "),(
                        this.diagnostic.rating.value > 1 &&
                            this.diagnostic.rating.value <= 50
                    )?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('title02'))}}):_vm._e(),_vm._v(" "),(
                        this.diagnostic.rating.value > 51 &&
                            this.diagnostic.rating.value <= 70
                    )?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('title03'))}}):_vm._e(),_vm._v(" "),(this.diagnostic.rating.value > 70)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('title04'))}}):_vm._e()])],1),_vm._v(" "),_c('div',{staticStyle:{"max-width":"500px","margin":"10px auto 10px  auto","padding":"0 40px","color":"white","font-size":"normal"}},[(this.diagnostic.rating.value == 0)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('subtitle01_01'))}}):_vm._e(),_vm._v(" "),(
                    this.diagnostic.rating.value > 1 &&
                        this.diagnostic.rating.value <= 50
                )?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('subtitle01_02'))}}):_vm._e(),_vm._v(" "),(
                    this.diagnostic.rating.value > 51 &&
                        this.diagnostic.rating.value <= 70
                )?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('subtitle01_03'))}}):_vm._e(),_vm._v(" "),(this.diagnostic.rating.value > 70)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('subtitle01_04'))}}):_vm._e()]),_vm._v(" "),(this.diagnostic.rating.value < 70)?_c('div',{staticStyle:{"max-width":"500px","margin":"10px auto 10px  auto","padding":"0 40px","color":"white","font-size":"normal"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('subtitle02'))}})]):_vm._e(),_vm._v(" "),_c('center',[_c('router-link',{attrs:{"to":"/recommendations"}},[_c('v-ons-button',{staticStyle:{"margin-top":"10px","margin-bottom":"10px","padding":"10px 30px","background-color":"#29ffa6 !important","color":"gray"}},[_vm._v("\n                    "+_vm._s(_vm.$t("btnRecommendations"))+"\n                ")])],1)],1),_vm._v(" "),_c('div',{staticStyle:{"max-width":"500px","margin":"10px auto 10px  auto","padding":"0 40px"}},[_c('div',{staticStyle:{"color":"white","font-size":"small","border-radius":"2px 2px 2px 2px","-moz-border-radius":"2px 2px 2px 2px","-webkit-border-radius":"2px 2px 2px 2px","border":"1px solid #ffffff","padding":"5px","text-align":"justify","text-justify":"inter-word"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('subtitle03'))}})])]),_vm._v(" "),_c('center',[_c('v-ons-button',{staticStyle:{"margin-top":"10px","margin-bottom":"10px","padding":"10px 30px","background-color":"#a4ffff !important","color":"black"},on:{"click":_vm.Continue_Click}},[_vm._v("\n                "+_vm._s(_vm.$t("btnContinue"))+"\n            ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }