<template>
    <v-ons-page>
        <v-ons-toolbar class="header">
            <div class="center">
                <center><img src="/assets/images/mobile/logo.svg" /></center>
            </div>
        </v-ons-toolbar>

        <div class="background"></div>

        <div class="content" style="background-color:white !important;">
            <v-ons-toast :visible.sync="showErrors" animation="ascend">
                <ul>
                    <li v-for="error in errors" v-bind:key="error">
                        {{ error }}
                    </li>
                </ul>
                <button @click="showErrors = false">
                    {{ $t("btnClose") }}
                </button>
            </v-ons-toast>

            <h1 class="text_blue" style="padding: 0px 15px;">
                {{ $t("personal_information") }}
            </h1>
            <p class="text_blue" style="padding: 0px 15px;">
                {{ $t("subtitle") }}
            </p>

            <v-ons-list>
                <!-- NOMBRE Y APELLIDOS -->
                <v-ons-list-item>
                    <div class="text_blue" style="width:95%;">
                        <p>{{ $t("name_lastname") }}</p>
                        <v-ons-input
                            class="bordered"
                            type="text"
                            v-model="name_lastname"
                        ></v-ons-input>
                    </div>
                </v-ons-list-item>
                <!-- EDAD -->
                <v-ons-list-item>
                    <div
                        class="text_blue bold"
                        style="width:100%; padding:0px 5px;"
                    >
                        <p>{{ $t("age") }}</p>
                        <center>
                            <span
                                v-if="(Number(age) < 99) & (Number(age) >= 16)"
                                >{{ $t("years") }} = {{ age }}</span
                            >
                            <span v-else-if="Number(age) < 16">{{
                                $t("less_16_years")
                            }}</span>
                            <span v-else>{{ $t("more_100_years") }}</span>
                        </center>
                        <v-ons-row>
                            <v-ons-col
                                width="65px"
                                style="text-align: center; line-height: 31px;"
                                @click="age > 15 ? age-- : (age = 15)"
                            >
                                <v-ons-icon icon="fa-minus-square"></v-ons-icon>
                            </v-ons-col>
                            <br />
                            <v-ons-col>
                                <center>
                                    <v-ons-range
                                        v-model.number="age"
                                        style="width: 100%;"
                                        min="15"
                                        max="101"
                                        step="1"
                                    ></v-ons-range>
                                </center>
                            </v-ons-col>
                            <br />
                            <v-ons-col
                                width="65px"
                                style="text-align: center; line-height: 31px;"
                                @click="age < 101 ? age++ : (age = 101)"
                            >
                                <v-ons-icon icon="fa-plus-square"></v-ons-icon>
                            </v-ons-col>
                        </v-ons-row>
                    </div>
                </v-ons-list-item>
                <!-- SEXO -->
                <v-ons-list-item>
                    <div class="text_blue">
                        <p>{{ $t("sex") }}</p>
                        <center>
                            <v-ons-row>
                                <v-ons-col>
                                    <div>
                                        <img
                                            src="/assets/images/mobile/male.png"
                                            style="width:15%; vertical-align: middle; padding:10px;"
                                        />
                                        <v-ons-radio
                                            :input-id="'radio-male'"
                                            :value="'true'"
                                            v-model="selectedSex"
                                            style="vertical-align: middle;"
                                        ></v-ons-radio>
                                        <label
                                            :for="'radio-male'"
                                            style="padding:10px;"
                                            >{{ $t("male") }}</label
                                        >
                                    </div>
                                </v-ons-col>
                                <v-ons-col>
                                    <div>
                                        <img
                                            src="/assets/images/mobile/female.png"
                                            style="width:15%; vertical-align: middle; padding:10px;"
                                        />
                                        <v-ons-radio
                                            :input-id="'radio-female'"
                                            :value="'false'"
                                            v-model="selectedSex"
                                            style="vertical-align: middle;"
                                        ></v-ons-radio>
                                        <label
                                            :for="'radio-female'"
                                            style="padding:10px;"
                                            >{{ $t("female") }}</label
                                        >
                                    </div>
                                </v-ons-col>
                            </v-ons-row>
                        </center>
                    </div>
                </v-ons-list-item>
                <!-- EMAIL -->
                <v-ons-list-item>
                    <div class="text_blue" style="width:95%;">
                        <p>{{ $t("email") }}</p>
                        <v-ons-input
                            class="bordered"
                            type="email"
                            float
                            v-model="email"
                        ></v-ons-input>
                    </div>
                </v-ons-list-item>
                <!-- ADDRESS STREET 1 -->
                <v-ons-list-item>
                    <div class="text_blue" style="width:95%;">
                        <p>
                            <b>{{ $t("address") }}</b>
                        </p>
                        <p>{{ $t("street_1") }}</p>
                        <v-ons-input
                            class="bordered"
                            type="text"
                            float
                            v-model="street_1"
                        ></v-ons-input>
                    </div>
                </v-ons-list-item>
                <!-- ADDRESS STREET 2 -->
                <v-ons-list-item>
                    <div class="text_blue" style="width:95%;">
                        <p>{{ $t("street_2") }}</p>
                        <v-ons-input
                            class="bordered"
                            float
                            type="text"
                            v-model="street_2"
                        ></v-ons-input>
                    </div>
                </v-ons-list-item>
                <!-- ADDRESS CITY -->
                <v-ons-list-item>
                    <div class="text_blue" style="width:95%;">
                        <p>{{ $t("city") }}</p>
                        <v-ons-input
                            class="bordered"
                            float
                            type="text"
                            v-model="city"
                        ></v-ons-input>
                    </div>
                </v-ons-list-item>
                <!-- ADDRESS REGION -->
                <v-ons-list-item>
                    <div class="text_blue" style="width:95%;">
                        <p>{{ $t("region") }}</p>
                        <v-ons-input
                            class="bordered"
                            float
                            type="text"
                            v-model="state"
                        ></v-ons-input>
                    </div>
                </v-ons-list-item>
                <!-- ADDRESS ZIP CODE -->
                <v-ons-list-item>
                    <div class="text_blue" style="width:95%;">
                        <p>{{ $t("zipcode") }}</p>
                        <v-ons-input
                            class="bordered"
                            float
                            type="number"
                            v-model="zipcode"
                        ></v-ons-input>
                    </div>
                </v-ons-list-item>
                <!-- ADDRESS COUNTRY -->
                <v-ons-list-item>
                    <div class="text_blue" style="width:95%;">
                        <p>{{ $t("country") }}</p>
                        <v-ons-input
                            class="bordered"
                            float
                            type="text"
                            v-model="country"
                        ></v-ons-input>
                    </div>
                </v-ons-list-item>
                <!-- DOCTORs EMAIL -->
                <v-ons-list-item>
                    <div class="text_blue" style="width:95%;">
                        <p>{{ $t("doctor_email") }}</p>
                        <v-ons-input
                            class="bordered"
                            float
                            type="mail"
                            v-model="doctor_email"
                        ></v-ons-input>
                    </div>
                </v-ons-list-item>
            </v-ons-list>

            <center>
                <v-ons-button
                    style="margin-top:50px; margin-bottom:50px; padding: 10px 100px; background-color: #a4ffff !important; color:black;"
                    @click="Continue_Click"
                >
                    {{ $t("btnContinue") }}
                </v-ons-button>
            </center>
        </div>
    </v-ons-page>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const API_KEY = "AIzaSyAKj36Z8NKG0-NfBg2n3_6appptuEUa2zw";

export default {
    name: "PersonalData",
    data() {
        return {
            errors: [],
            showErrors: false,
            name_lastname: "",
            age: 15,
            selectedSex: "",
            email: "",
            street_1: "",
            street_2: "",
            city: "",
            state: "",
            zipcode: "",
            country: "",
            lat: 0,
            long: 0,
            doctor_email: ""
        };
    },
    mounted() {
        if (localStorage.name_lastname)
            this.name_lastname = localStorage.name_lastname;
        if (localStorage.email) this.email = localStorage.email;
        if (localStorage.selectedSex)
            this.selectedSex = localStorage.selectedSex;
        if (localStorage.age) this.age = parseInt(localStorage.age);
        if (localStorage.doctor_email)
            this.doctor_email = localStorage.doctor_email;

        if ("geolocation" in navigator) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        this.latitude = position.coords.latitude;
                        this.longitude = position.coords.longitude;
                        this.getStreetAddressFrom(
                            this.latitude,
                            this.longitude
                        );
                    },
                    error => {
                        console.log(error.message);
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: 15000,
                        maximumAge: 0
                    }
                );
            }
        } else {
            console.log("geolocation IS NOT available");
        }
    },
    watch: {
        name_lastname(value) {
            localStorage.name_lastname = value;
        },
        email(value) {
            localStorage.email = value;
        },
        selectedSex(value) {
            localStorage.selectedSex = value;
        },
        age(value) {
            localStorage.age = value;
        },
        doctor_email(value) {
            localStorage.doctor_email = value;
        }
    },
    methods: {
        ...mapActions(["setUser"]),
        validEmail: function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        Continue_Click: function(event) {
            this.showErrors = false;
            this.errors = [];
            if (!this.email) this.errors.push(this.$t("error_emailMandatory"));
            else if (!this.validEmail(this.email))
                this.errors.push(this.$t("error_emailNotValid"));

            if (this.age < 16) this.errors.push(this.$t("error_ageNotValid"));

            if (this.errors.length > 0) this.showErrors = true;
            else {
                this.setUser({
                    name_lastname: this.name_lastname,
                    age: this.age,
                    sex: this.selectedSex == "true",
                    email: this.email,
                    gps_coordinates: {
                        latitude: this.latitude,
                        longitude: this.longitude
                    },
                    address: {
                        street_1: this.street_1,
                        street_2: this.street_2,
                        city: this.city,
                        state: this.state,
                        zipcode: this.zipcode,
                        country: this.country
                    },
                    doctor_email: this.doctor_email,
                    default_locale: localStorage.language
                });

                // Se establecen las notificaciones para este usuario
                if (
                    localStorage.disableNotification &&
                    localStorage.disableNotification == "true"
                )
                    console.log(
                        "No se activan las notificaciones a peticion del usuario"
                    );
                else this.initialiseSubcriptions();

                this.$emit("change-Page", "autodiagnostic");
            }
        },
        async getStreetAddressFrom(lat, long) {
            try {
                var config = {
                    headers: { "Access-Control-Allow-Origin": "*" }
                };
                var mapURL =
                    "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                    lat +
                    "," +
                    long +
                    "&key=" +
                    API_KEY;
                this.$http.get(mapURL).then(
                    response => {
                        var addressComponent =
                            response.data.results[0].address_components;
                        var street_name;
                        var street_number;

                        street_number = this.getAddressSection(
                            addressComponent,
                            "street_number"
                        );
                        street_name = this.getAddressSection(
                            addressComponent,
                            "route"
                        );
                        this.street_1 = street_name + ", " + street_number;
                        this.city = this.getAddressSection(
                            addressComponent,
                            "administrative_area_level_2"
                        );
                        this.state = this.getAddressSection(
                            addressComponent,
                            "administrative_area_level_1"
                        );
                        this.zipcode = this.getAddressSection(
                            addressComponent,
                            "postal_code"
                        );
                        this.country = this.getAddressSection(
                            addressComponent,
                            "country"
                        );
                    },
                    () => {}
                );
            } catch (error) {
                console.error(error.message);
            }
        },
        getAddressSection: function(addressComponent, address_key) {
            for (var x = 0; x < addressComponent.length; x++) {
                var chk = addressComponent[x];
                if (chk.types[0] == address_key) {
                    return chk.long_name;
                }
            }
            return "";
        },
        initialiseSubcriptions() {
            if (!("showNotification" in ServiceWorkerRegistration.prototype)) {
                console.log("Notifications aren't supported.");
                return;
            }
            if (Notification.permission === "denied") {
                console.log("The user has blocked notifications.");
                return;
            }
            if (!("PushManager" in window)) {
                console.log("Push messaging isn't supported.");
                return;
            }
            navigator.serviceWorker.ready.then(registration => {
                registration.pushManager
                    .getSubscription()
                    .then(subscription => {
                        if (!subscription) {
                            console.log(
                                "No hay una subscripcion activa, se solicita una nueva"
                            );
                            this.registerNewSubscription();
                        } else {
                            console.log(
                                "Se recupera subscripcion existente y se actualiza la BD"
                            );
                            this.updateSubscriptionDB(subscription);
                        }
                    })
                    .catch(ex => {
                        console.error("Error during getSubscription()", ex);
                    });
            });
        },
        registerNewSubscription() {
            navigator.serviceWorker.ready.then(registration => {
                const options = { userVisibleOnly: true };
                const vapidPublicKey = process.env.MIX_VAPID_PUBLIC_KEY;
                if (vapidPublicKey) {
                    console.log(
                        "Se procesa registro con clave publica: " +
                            vapidPublicKey
                    );
                    options.applicationServerKey = this.urlBase64ToUint8Array(
                        vapidPublicKey
                    );
                    registration.pushManager
                        .subscribe(options)
                        .then(subscription => {
                            console.log("Se actualiza la BD");
                            this.updateSubscriptionDB(subscription);
                        })
                        .catch(ex => {
                            console.error("Error during subscribe()", ex);
                        });
                }
            });
        },
        updateSubscriptionDB(subscription) {
            const key = subscription.getKey("p256dh");
            const token = subscription.getKey("auth");
            const contentEncoding = (PushManager.supportedContentEncodings || [
                "aesgcm"
            ])[0];

            const data = {
                patient_email: localStorage.email,
                endpoint: subscription.endpoint,
                key: key
                    ? btoa(String.fromCharCode.apply(null, new Uint8Array(key)))
                    : null,
                token: token
                    ? btoa(
                          String.fromCharCode.apply(null, new Uint8Array(token))
                      )
                    : null,
                contentEncoding: contentEncoding
            };

            axios
                .post("/api/subscriptions", data)
                .then(() => {
                    console.log(
                        "Subcripcion a notificaciones actualizada correctamente en BD"
                    );
                })
                .catch(ex => {
                    console.error(
                        "Error al intentar registrar la informacion de la subscripcion en el servidor"
                    );
                });
        },
        urlBase64ToUint8Array(base64String) {
            const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
            const base64 = (base64String + padding)
                .replace(/-/g, "+")
                .replace(/_/g, "/");
            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);
            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        }
    }
};
</script>

<style scoped>
.bordered {
    display: block;
    border-radius: 5px;
    border: 1px solid black;
}

.text_blue {
    color: #033f70;
    font-family: raleway !important;
}
</style>

<i18n>
{
  "en": {
    "personal_information": "Personal Information",
    "subtitle": "Please provide the following personal data. It will only be used during the self-evaluation process.",
    "name_lastname": "Full Name (optional)",
    "age": "Age (optional)",
    "more_100_years": "More than 100 years",
    "less_16_years": "Less than 16 years",
    "years": "Years",
    "sex": "Gender (optional)",
    "male": "Male",
    "female": "Female",
    "email": "E-mail",
    "address": "Current Location (optional - complete if incorrect)",
    "street_1": "Address",
    "street_2": "Additional Information",
    "city": "City",
    "region": "Region / Province / State",
    "zipcode": "Zip Code",
    "country": "Country",
    "doctor_email": "HCP´s E-mail (optional)",
    "error_emailMandatory": "E-Mail field cannot be blank.",
    "error_emailNotValid": "E-Mail field must be in a valid format.",
    "error_ageNotValid": "Program intended for users over 16 years of age.",
    "btnContinue": "CONTINUE",
    "btnClose": "CLOSE",
    "copyright": "COPYRIGHT @ 2020 SURFERNET. ALL RIGHTS RESERVED."
  },
  "es": {
    "personal_information": "Información Personal",
    "subtitle": "Proporciónanos tus datos personales. Utilizaremos estos datos únicamente para el proceso de autoevaluación.",
    "name_lastname": "Nombre y Apellidos (opcional)",
    "age": "Edad (opcional)",
    "more_100_years": "Más de 100 años",
    "less_16_years": "Menos de 16 años",
    "years": "Años",
    "sex": "Sexo (opcional)",
    "male": "Hombre",
    "female": "Mujer",
    "email": "E-mail",
    "address": "Posición Actual (opcional - completar si es incorrecta)",
    "street_1": "Dirección",
    "street_2": "Información adicional",
    "city": "Ciudad",
    "region": "Región / Provincia / Estado",
    "zipcode": "Código Postal",
    "country": "País",
    "doctor_email": "E-mail de tu médico (opcional)",
    "error_emailMandatory": "El campo E-Mail es obligatorio.",
    "error_emailNotValid": "El campo E-Mail debe ser contener una dirección de válida.",
    "error_ageNotValid": "Programa válido únicamente para mayores de 16 años.",
    "btnContinue": "CONTINUAR",
    "btnClose": "CERRAR",
    "copyright": "COPYRIGHT @ 2020 SURFERNET. ALL RIGHTS RESERVED."
  }
}
</i18n>
